import { css } from "@emotion/react";
import type { ComponentProps } from "react";
import { useMemo } from "react";

import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";
import type { NumberFormatProps } from "@/components/NumberFormat/types";

const cssNumericFont = css`
  font-variant-numeric: tabular-nums;
`;

type NumberFormatComponentProps = { value: unknown } & NumberFormatProps &
  ComponentProps<"span">;

/**
 * A simple wrapper component for displaying numbers with consistent formatting
 * @param nullishValue - Optionally customize the default fallback value
 * @param numberFormatPreset - Select a number formatting preset: count (default), percent, revenue
 * @param numberFormatLocale - A standard locale e.g. `en-US` or `zh-TW`
 * @param numberFormatOptions - Standard `Intl.NumberFormat` options object
 * @param value - Any value in need of formatting
 *
 * @example
 * ```tsx
 * <NumberFormat numberFormatPreset="count" value={value} />
 * ```
 */
export const NumberFormat: React.FC<NumberFormatComponentProps> = ({
  value,
  nullishValue,
  numberFormatPreset,
  numberFormatLocale,
  numberFormatOptions,
  ...restProps
}) => {
  const { numberFormat } = useNumberFormat({
    nullishValue,
    numberFormatPreset,
    numberFormatLocale,
    numberFormatOptions,
  });

  const valueFormatted = useMemo(
    () => numberFormat(value),
    [numberFormat, value],
  );

  return (
    <span css={cssNumericFont} {...restProps}>
      {valueFormatted}
    </span>
  );
};
