import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import { theme } from "@polifonia/theme";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Badge } from "@/components/Badge";
import { ExternalLink } from "@/components/ExternalLink";
import { Switch } from "@/components/Switch";
import { Typography } from "@/components/Typography";
import type { interludeClient } from "@/interlude";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";
import bindingImg from "@/pages/channels/pages/detail/wccs/image/binding.png";
import chatImg from "@/pages/channels/pages/detail/wccs/image/chat.png";
import trackingImg from "@/pages/channels/pages/detail/wccs/image/tracking.png";

const styles = defineStyles({
  featureCard: css({
    display: "flex",
    gap: "16px",
    border: `1px solid ${theme.colors.neutral030}`,
    borderRadius: "12px",
    paddingLeft: "24px",
    overflow: "hidden",
  }),
  featureContent: css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "20px 0",
  }),
  img: css({
    objectFit: "cover",
    width: "210px",
  }),
});

const Layout: React.FC<{
  control: React.ReactNode;
  content: React.ReactNode;
  image: React.ReactNode;
}> = ({ control, content, image }) => {
  return (
    <div css={styles.featureCard}>
      <Stack direction="row" gap={3} flex={1}>
        <Stack alignItems="center" justifyContent="center">
          {control}
        </Stack>
        <div css={styles.featureContent}>{content}</div>
      </Stack>
      {image}
    </div>
  );
};

type AvailableModules = "webChatModuleEnabled" | "webTrackingEnabled";

interface ModuleSwitchProps {
  module: AvailableModules;
}

const t = fakeT;
const moduleTranslationMap: Record<
  ModuleSwitchProps["module"],
  Record<"enabled" | "disabled", string>
> = {
  webChatModuleEnabled: {
    enabled: t("page.channel.tools.chat.enabled"),
    disabled: t("page.channel.tools.chat.disabled"),
  },
  webTrackingEnabled: {
    enabled: t("page.channel.tools.tracking.enabled"),
    disabled: t("page.channel.tools.tracking.disabled"),
  },
};

const moduleMapping = {
  webChatModuleEnabled: "wccsWebChatModuleEnabled",
  webTrackingEnabled: "wccsWebFootprintTrackingEnabled",
} satisfies Record<
  ModuleSwitchProps["module"],
  keyof Awaited<
    ReturnType<typeof interludeClient.organization.organizationSetting>
  >
>;

const ModuleSwitch: React.FC<ModuleSwitchProps> = ({ module }) => {
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const orgSettingQuery = interlude.organization.useOrganizationSetting();

  const orgSettingModuleEnabled = useMemo(() => {
    if (!orgSettingQuery.isSuccess) return false;
    return orgSettingQuery.data[moduleMapping[module]];
  }, [orgSettingQuery.isSuccess, orgSettingQuery.data, module]);

  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess(result) {
        if (result.type !== "wccs") return;
        const isEnabled = result.channelInformation[module];
        const messageKey =
          moduleTranslationMap[module][isEnabled ? "enabled" : "disabled"];
        enqueueSnackbar(t(messageKey), {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );

  const checked = useMemo(() => {
    if (channel.type !== "wccs") return false;
    if (!orgSettingModuleEnabled) return false;
    return channel.channelInformation[module];
  }, [
    channel.type,
    channel.channelInformation,
    orgSettingModuleEnabled,
    module,
  ]);

  const handleChange = useHandler<
    React.ComponentProps<typeof Switch>["onChange"]
  >(function handleChange(_, enabled) {
    mutation.mutate({
      channelInformation: {
        [module]: enabled,
      },
    });
  });

  return (
    <Switch
      disabled={mutation.isPending || !orgSettingModuleEnabled}
      checked={checked}
      onChange={handleChange}
    />
  );
};

const ModuleUpgradeBadge: React.FC<{ module: AvailableModules }> = ({
  module,
}) => {
  const orgSettingQuery = interlude.organization.useOrganizationSetting();

  const orgSettingModuleEnabled = useMemo(() => {
    if (!orgSettingQuery.isSuccess) return false;
    return orgSettingQuery.data[moduleMapping[module]];
  }, [orgSettingQuery.isSuccess, orgSettingQuery.data, module]);

  if (orgSettingModuleEnabled) return null;

  return (
    <Badge variant="attentive">
      <Trans i18nKey="page.channel.tools.badge.upgrade" />
    </Badge>
  );
};

export const Tools: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack direction="column" gap={2}>
      <Stack gap={3}>
        <Typography variant="h3" fontWeight={500}>
          {t("page.channel.tabs.tools")}
        </Typography>
        <Layout
          control={<ModuleSwitch module="webChatModuleEnabled" />}
          content={
            <>
              <Stack gap={0.5}>
                <Stack direction="row" gap="4px" alignItems="center">
                  <Typography
                    variant="h3"
                    color={theme.colors.staticFgTitle}
                    fontWeight={500}
                  >
                    {t("page.channel.tools.chat.title")}
                  </Typography>
                  <ModuleUpgradeBadge module="webChatModuleEnabled" />
                </Stack>
                <Typography>{t("page.channel.tools.chat.desc")}</Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <ExternalLink
                  href={t("page.channel.tools.chat.link")}
                  trailingIcon={true}
                >
                  {t("common.learnMore")}
                </ExternalLink>
              </Stack>
            </>
          }
          image={<img src={chatImg} css={styles.img} />}
        />
        <Layout
          control={<ModuleSwitch module="webTrackingEnabled" />}
          content={
            <>
              <Stack gap={0.5}>
                <Stack direction="row" gap="4px" alignItems="center">
                  <Typography
                    variant="h3"
                    color={theme.colors.staticFgTitle}
                    fontWeight={500}
                  >
                    {t("page.channel.tools.tracking.title")}
                  </Typography>
                  <ModuleUpgradeBadge module="webTrackingEnabled" />
                </Stack>
                <Typography>{t("page.channel.tools.tracking.desc")}</Typography>
              </Stack>
              <Stack direction="row" gap={2}>
                <ExternalLink
                  href={t("page.channel.tools.tracking.link")}
                  trailingIcon={true}
                >
                  {t("common.learnMore")}
                </ExternalLink>
              </Stack>
            </>
          }
          image={<img src={trackingImg} css={styles.img} />}
        />
        <Layout
          control={<Switch disabled />}
          content={
            <>
              <Stack gap={0.5}>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography
                    variant="h3"
                    color={theme.colors.staticFgTitle}
                    fontWeight={500}
                  >
                    {t("page.channel.tools.binding.title")}
                  </Typography>
                  <Badge variant="primary">
                    {t("page.channel.tools.upComing")}
                  </Badge>
                </Stack>
                <Typography>{t("page.channel.tools.binding.desc")}</Typography>
              </Stack>
            </>
          }
          image={<img src={bindingImg} css={styles.img} />}
        />
      </Stack>
    </Stack>
  );
};
