import type { FeatureSettings } from "@polifonia/interlude/api/organization";
import { useMemo } from "react";

import { interlude } from "@/interlude";

export const useFeatureSettings = (): {
  isFeatureSettingsValid: boolean;
  featureSettings: FeatureSettings | undefined;
} => {
  const organizationSettingQuery =
    interlude.organization.useOrganizationSetting();

  const featureSettings = useMemo(() => {
    return organizationSettingQuery.data?.maacFeatureSettings;
  }, [organizationSettingQuery.data]);

  const isFeatureSettingsValid = useMemo(
    () =>
      organizationSettingQuery.isFetched &&
      organizationSettingQuery.data !== undefined &&
      organizationSettingQuery.data?.maacFeatureSettings !== undefined,
    [organizationSettingQuery.data, organizationSettingQuery.isFetched],
  );

  return {
    isFeatureSettingsValid,
    featureSettings,
  };
};
