import { deepClone } from "@chatbotgang/etude/object/deepClone";
import { ENV, SERVER_REGION, STATSIG_CLIENT_API_KEY } from "@polifonia/env";
import {
  StatsigContext,
  StatsigProvider as OriginalStatsigProvider,
  useClientAsyncInit,
} from "@statsig/react-bindings";
import { useContext, useMemo } from "react";

import { BarLoading } from "@/components/Loading/BarLoading";

/**
 * The original context is not reactive to UI updates. This custom provider
 * ensures reactivity by adding renderVersion to the context object. A deep
 * clone is used to create a new object, triggering effects and re-rendering the
 * UI.
 */
const InjectReactiveStatsigClientProvider: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  const ctx = useContext(StatsigContext);
  const renderVersion = ctx.renderVersion;

  const statsigClient: typeof ctx = useMemo(() => {
    return {
      ...deepClone(ctx),
      renderVersion,
    } satisfies typeof ctx;
  }, [ctx, renderVersion]);

  return <StatsigContext.Provider value={statsigClient} {...props} />;
};

const StatsigProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { client, isLoading } = useClientAsyncInit(
    STATSIG_CLIENT_API_KEY,
    {
      userID: "admin-center",
      custom: {
        region: SERVER_REGION,
      },
    },
    {
      environment: {
        /**
         * Available environments: development, staging, production
         * This is due to free tier Statsig only supports these three environments.
         */
        tier: ENV,
      },
    },
  );

  if (isLoading) {
    return <BarLoading wrapperStyle={{ margin: "auto" }} />;
  }

  return (
    <OriginalStatsigProvider client={client}>
      <InjectReactiveStatsigClientProvider>
        {children}
      </InjectReactiveStatsigClientProvider>
    </OriginalStatsigProvider>
  );
};

export { StatsigProvider };
