import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Checkbox, { type CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import { createContext } from "@polifonia/utils/react/createContext";
import { createZustandContextStore } from "@polifonia/utils/react/createZustandContextStore";
import { shallow } from "@polifonia/utils/zustand/shallow";
import useSwitch from "@react-hook/switch";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSnackbar } from "notistack";
import { type FC, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { Button } from "@/components/Button";
import { DiscList } from "@/components/DiscList";
import { ExternalLink } from "@/components/ExternalLink";
import { KeyIcon } from "@/components/Icons/KeyIcon";
import { SyncIcon } from "@/components/Icons/SyncIcon";
import { WarningIcon } from "@/components/Icons/WarningIcon";
import { BarLoading } from "@/components/Loading/BarLoading";
import { Modal } from "@/components/Modal";
import { Tag } from "@/components/Tag";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import { ProfileUnificationWarning } from "@/features/unify-scope-setting/ProfileUnificationWarning";
import { UnifyKeyLabel } from "@/features/unify-scope-setting/UnifyKeyLabel";
import { UnifyKeySelector } from "@/features/unify-scope-setting/UnifyKeySelector";
import { interlude, type InterludeTypes } from "@/interlude";
import { DisconnectCdh } from "@/pages/applications/pages/cdh/components/DisconnectCdh";
import { ProfileMergingTooltip } from "@/pages/applications/pages/cdh/components/ProfileMergingTooltip";

dayjs.extend(duration);

const cssUnification = {
  self: css({
    display: "flex",
    gap: "48px",
    flexDirection: "column",
    width: "100%",
    maxWidth: "512px",
  }),
  title: css({
    marginBottom: "8px",
  }),
  description: css({
    color: theme.colors.neutral070,
    fontSize: "0.875rem",
  }),
  unifyKey: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "relative",
  }),
  unifyKeyTitle: css({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    svg: {
      fontSize: "1rem",
      color: theme.colors.neutral060,
    },
  }),
} satisfies Record<string, ReturnType<typeof css>>;

const UnifyKeys = (() => {
  const cssUnifyKeys = {
    self: css({
      display: "flex",
      gap: "8px",
    }),
  };

  return fc<{
    items: Array<InterludeTypes["UnificationKeys"]>;
  }>(function UnifyKeys({ items }) {
    if (items.length === 0) return <>{EMPTY_STRING_PLACEHOLDER}</>;

    return (
      <div css={cssUnifyKeys.self}>
        {items.map((unifyKey) => (
          <Tag key={unifyKey} label={<UnifyKeyLabel unifyKey={unifyKey} />} />
        ))}
      </div>
    );
  });
})();

const MergingState: FC = () => {
  const mergingStateQuery = interlude.unifyScopeSetting.useGetUnifyMergeState();

  if (!mergingStateQuery.isSuccess || mergingStateQuery.data.isFinished)
    return null;

  return (
    <Alert icon={<SyncIcon />} variant="outlined" severity="info">
      <Trans i18nKey="cdh.unification.merging.label" />
    </Alert>
  );
};

const EditWarning: FC = () => (
  <div css={{ display: "flex", gap: "6px", color: theme.colors.yellow060 }}>
    <WarningIcon />
    <Trans i18nKey="cdh.unification.editWarning.label" />
  </div>
);

const {
  Provider: UnifyKeySettingProvider,
  useStore: useUnifyKeySettingStore,
  useUseStore: useUseUnifyKeySettingStore,
} = createZustandContextStore<{
  editing: boolean;
}>()(
  () => ({
    editing: false,
  }),
  shallow,
);

const UnifyKey = (() => {
  const cssUnifyKey = {
    self: css({
      display: "flex",
      gap: "24px",
      flexDirection: "column",
    }),
  };

  type UnifySettingFormProps = {
    unifyKeys: Array<InterludeTypes["UnificationKeys"]>;
  };

  const UnifySettingFormInitialValue = createContext<
    UnifySettingFormProps | undefined
  >({
    name: "UnifySettingFormInitialValue",
  });

  const COUNT_DOWN_MS = dayjs.duration({ seconds: 5 }).asMilliseconds();

  const UnifySettingForm: FC = () => {
    const { t } = useTranslation();
    const defaultValues = UnifySettingFormInitialValue.useContext();
    const form = useForm<UnifySettingFormProps>({
      mode: "all",
      defaultValues,
    });
    const { enqueueSnackbar } = useSnackbar();
    const useUnifyKeySettingStore = useUseUnifyKeySettingStore();
    const [open, toggleConfirmModal] = useSwitch();
    const [consentChecked, setConsentChecked] = useState(false);

    const mutation = interlude.unifyScopeSetting.useUpdateUnifyKeys(
      {},
      {
        onSuccess() {
          enqueueSnackbar(t("common.updatedSuccessfully"), {
            variant: "success",
          });
          cleanup();
        },
      },
    );

    const cleanup = useHandler(function cleanup() {
      toggleConfirmModal.off();
      setConsentChecked(false);
      useUnifyKeySettingStore.setState({ editing: false });
      mutation.reset();
      form.reset();
    });

    const onConsentChange = useHandler<CheckboxProps["onChange"]>(
      function onConsentChange(e) {
        setConsentChecked(e.target.checked);
      },
    );
    const onFinish = useHandler<Parameters<typeof form.handleSubmit>[0]>(
      function onSubmit() {
        toggleConfirmModal.on();
      },
    );

    const onSubmit = useHandler(function onSubmit() {
      if (mutation.isPending) return;
      const values = form.getValues();
      mutation.mutate(values);
    });

    return (
      <form
        onSubmit={form.handleSubmit(onFinish)}
        css={{ display: "flex", flexDirection: "column", gap: "inherit" }}
      >
        <Controller
          name="unifyKeys"
          control={form.control}
          rules={{ required: true }}
          render={({ field, formState }) => (
            <UnifyKeySelector
              showCount
              error={Boolean(formState.errors.unifyKeys)}
              value={field.value ?? defaultValues.unifyKeys}
              onChange={field.onChange}
              onBlur={field.onBlur}
              helperText={
                !formState.errors.unifyKeys ? null : (
                  <Trans i18nKey="validation.unification.unifyKeys.min" />
                )
              }
            />
          )}
        />
        <EditWarning />
        <div
          css={css({
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          })}
        >
          <Button variant="primary" disabled={mutation.isPending} type="submit">
            <Trans i18nKey="cdh.unification.updateForm.confirm.label" />
          </Button>
          <Button variant="plain" onClick={cleanup}>
            <Trans i18nKey="common.cancel" />
          </Button>
        </div>
        <Modal.Warning
          keepMounted={false}
          open={open}
          handleClose={toggleConfirmModal.off}
          title={t("cdh.unification.confirmChange.title")}
          footer={
            <Stack gap="12px" direction="row" justifyContent="flex-end">
              <Button
                key="cancel"
                variant="plain"
                onClick={toggleConfirmModal.off}
              >
                <Trans i18nKey="common.cancel" />
              </Button>
              <Button
                key="submit"
                variant="primary"
                onClick={onSubmit}
                clickCountDownMs={COUNT_DOWN_MS}
                disabled={!consentChecked || mutation.isPending}
              >
                <Trans i18nKey="common.confirm" />
              </Button>
            </Stack>
          }
        >
          <div
            css={css({
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              textAlign: "left",
            })}
          >
            <Trans i18nKey="cdh.unification.confirmChange.desc" />
            <ExternalLink
              trailingIcon
              href={t("cdh.unification.confirmChange.learnMore.link")}
            >
              <Trans i18nKey="cdh.unification.confirmChange.learnMore" />
            </ExternalLink>
            <FormControlLabel
              control={
                <Checkbox checked={consentChecked} onChange={onConsentChange} />
              }
              label={
                <Trans i18nKey="cdh.unification.confirmChange.consent.label" />
              }
            />
          </div>
        </Modal.Warning>
      </form>
    );
  };

  const ToggleEditButton: FC = () => {
    const useUnifyKeySettingStore = useUseUnifyKeySettingStore();
    const onEdit = useHandler(function onEdit() {
      useUnifyKeySettingStore.setState({ editing: true });
    });
    const mergingStateQuery =
      interlude.unifyScopeSetting.useGetUnifyMergeState();

    if (mergingStateQuery.isError)
      return <Alert severity="error">{mergingStateQuery.error.message}</Alert>;

    if (mergingStateQuery.isPending)
      return (
        <Button variant="plain" loading disabled>
          <Trans i18nKey="cdh.unification.unifyKey.edit.label" />
        </Button>
      );

    return (
      <ProfileMergingTooltip disabled={mergingStateQuery.data.isFinished}>
        <span>
          <Button
            variant="plain"
            onClick={onEdit}
            disabled={!mergingStateQuery.data.isFinished}
          >
            <Trans i18nKey="cdh.unification.unifyKey.edit.label" />
          </Button>
        </span>
      </ProfileMergingTooltip>
    );
  };

  return fc(function UnifyKey() {
    const unifyScopeSettingQuery =
      interlude.unifyScopeSetting.useGetUnifyScopeSetting();

    const { editing } = useUnifyKeySettingStore();

    const unifyKeys = useMemo(
      function getUnifyKeys() {
        if (!unifyScopeSettingQuery.isSuccess) return [];

        return unifyScopeSettingQuery.data.unifyKeys.map(
          (key) => key.attribute,
        );
      },
      [
        unifyScopeSettingQuery.isSuccess,
        unifyScopeSettingQuery.data?.unifyKeys,
      ],
    );

    if (unifyScopeSettingQuery.isPending) return <BarLoading />;

    if (unifyScopeSettingQuery.isError)
      return (
        <Alert severity="error">{unifyScopeSettingQuery.error.message}</Alert>
      );

    return (
      <div css={cssUnifyKey.self}>
        {editing ? null : (
          <>
            <UnifyKeys items={unifyKeys} />
            {unifyKeys.length === 0 ? null : (
              <ProfileUnificationWarning.UnifyScope
                orgs={unifyScopeSettingQuery.data.orgs}
              />
            )}
            <MergingState />
          </>
        )}
        {!editing ? null : (
          <UnifySettingFormInitialValue.Provider value={{ unifyKeys }}>
            <UnifySettingForm />
          </UnifySettingFormInitialValue.Provider>
        )}
        <div>{editing ? null : <ToggleEditButton />}</div>
      </div>
    );
  });
})();

const ProfileApiAdBanner = (() => {
  const cssProfileApiAdBanner = {
    self: css({
      display: "flex",
      position: "relative",
      padding: "16px 24px",
      flexDirection: "column",
      gap: "8px",
      borderLeft: `4px solid ${theme.colors.blue050}`,
      background: theme.colors.neutral010,
    }),
    title: css({
      flex: "1 0 0",
      color: theme.colors.neutral100,
      fontSize: "0.875rem",
      fontWeight: 500,
    }),
    content: css({
      alignSelf: "stretch",
      color: theme.colors.neutral090,
      fontSize: "0.875rem",
    }),
    ribbon: css({
      position: "absolute",
      right: 0,
      top: 0,
      padding: "4px 12px",
      color: theme.colors.white,
      borderRadius: "0 0 0 12px",
      background: theme.colors.blue060,
    }),
  };

  return fc(function ProfileAPIAdBanner() {
    const { t } = useTranslation();
    return (
      <div css={cssProfileApiAdBanner.self}>
        <div css={cssProfileApiAdBanner.ribbon}>API</div>
        <div css={cssProfileApiAdBanner.title}>
          <Trans i18nKey="profile.api.ad.banner.title" />
        </div>
        <div css={cssProfileApiAdBanner.content}>
          <Trans i18nKey="profile.api.ad.banner.content" />
        </div>
        <ExternalLink
          trailingIcon
          href={t("profile.api.ad.banner.learnMore.link")}
        >
          <Trans i18nKey="profile.api.ad.banner.learnMore.label" />
        </ExternalLink>
      </div>
    );
  });
})();

export const CdhPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div css={cssUnification.self}>
      <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          <Typography variant="body">
            <Trans i18nKey="applications.title" />
          </Typography>
          <Typography variant="body">
            <Trans i18nKey="applications.cdh.title" />
          </Typography>
        </Breadcrumbs>
        <Typography variant="h1">
          <Trans i18nKey="applications.cdh.heading" />
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "24px", flexDirection: "column" }}>
        <div>
          <Typography variant="h2" css={cssUnification.title}>
            <Trans i18nKey="cdh.unification.title" />
          </Typography>
          <DiscList
            items={t("cdh.unification.description")}
            css={cssUnification.description}
          />
        </div>
        <div css={cssUnification.unifyKey}>
          <div css={cssUnification.unifyKeyTitle}>
            <Typography variant="body" fontWeight={700}>
              <Trans i18nKey="cdh.unification.unifyKey.label" />
            </Typography>
            <KeyIcon />
          </div>
          <UnifyKeySettingProvider>
            <UnifyKey />
          </UnifyKeySettingProvider>
        </div>
        <ProfileApiAdBanner />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <div>
          <Typography variant="h2" css={cssUnification.title}>
            <Trans i18nKey="cdh.unification.disconnect.title" />
          </Typography>
          <Typography variant="body" css={cssUnification.description}>
            <Trans i18nKey="cdh.unification.disconnect.desc" />
          </Typography>
        </div>
        <DisconnectCdh />
      </Box>
    </div>
  );
};
