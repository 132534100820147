import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { SERVER_REGION } from "@polifonia/env";
import { useStatsigUser } from "@statsig/react-bindings";
import { useEffect } from "react";

import { rootQueriesContext } from "@/queriesContext/rootQueriesContext";

/**
 * Updates the Statsig user with the current user's organization information.
 */
const BindStatsigUser: React.FC = () => {
  const { updateUserSync: originalUpdateUserSync } = useStatsigUser();
  const { me } = rootQueriesContext.useData();
  const updateUserSync = useHandler(originalUpdateUserSync);

  useEffect(
    function bindStatsigUser() {
      updateUserSync({
        custom: {
          org_name: me.organization.name,
          release_tier: me.organization.releaseTier,
          region: SERVER_REGION,
        },
        customIDs: {
          org_uuid: me.organization.uuid,
        },
      });
    },
    [
      me.organization.name,
      me.organization.uuid,
      me.organization.releaseTier,
      updateUserSync,
    ],
  );

  return null;
};

export { BindStatsigUser };
