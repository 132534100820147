const httpsUrlWithOptionalWildcardRegex =
  /^https:\/\/(\*\.)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])\.)+[a-zA-Z]{2,6}(?::([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))?\/?$/;

const isValidDomain = Object.assign(
  function isValidURL(url: string): boolean {
    return httpsUrlWithOptionalWildcardRegex.test(url);
  },
  {
    regex: httpsUrlWithOptionalWildcardRegex,
  },
);

export { isValidDomain };
