import { makeApi } from "@zodios/core";
import { z } from "zod";

export const CategoryEnum = {
  TAG: 1,
  ENGAGEMENT_LEVEL: 2,
  JOIN_TIME: 3,
  LAST_ENGAGED: 4,
  GENDER: 5,
  PAGE_VIEW: 6,
  BROADCAST_CLICK: 7,
  TRACELINK_CLICK: 8,
  AVERAGE_TRANSACTION_REVENUE: 9,
  ACCUMULATED_REVENUE: 10,
  BIRTHDAY: 13,
  CUSTOMER_ID: 14,
  PHONE: 15,
  EMAIL: 16,
  CAMPAIGN_CLICK: 17,
  ANY_TAG: 19,
  CREATED_SOURCE: 20,
  OPEN_COUNT: 21,
  AUTO_SEGMENT: 22,
  TAG_INTENSITY: 23,
  CDP_TAG: 24,
  CUSTOM: 99 /** Default value */,
} as const;

export const CategorySchema = z.nativeEnum(CategoryEnum);
export type Category = z.infer<typeof CategorySchema>;

export const TriggerTypeEnum = {
  DEFAULT: "default",
  BIND: "bind",
  POSTBACK: "postback",
  TAG: "tag",
  TAG_WITH_BOUND: "tag_with_bound",
} as const;

export const TriggerTypeSchema = z.nativeEnum(TriggerTypeEnum);
export type TriggerType = z.infer<typeof TriggerTypeSchema>;

export const JourneyNodeTriggerTypeEnum = {
  Empty: "empty",
  TagAdded: "tag_added",
  GaPageView: "ga_page_view",
  GaAddToCart: "ga_add_to_cart",
  GaPurchase: "ga_purchase",
  LineMessageOpened: "line_message_opened",
} as const;

const JourneyNodeTriggerTypeSchema = z.nativeEnum(JourneyNodeTriggerTypeEnum);
export type JourneyNodeTriggerType = z.infer<
  typeof JourneyNodeTriggerTypeSchema
>;

export const JourneyNodeRuleTypeEnum = {
  Exit: "exit",
  TimeDelay: "time_delay",
  TimeSchedule: "time_schedule",
  YesNoBranch: "yes_no_branch",
} as const;

const JourneyNodeRuleTypeSchema = z.nativeEnum(JourneyNodeRuleTypeEnum);
export type JourneyNodeRuleType = z.infer<typeof JourneyNodeRuleTypeSchema>;

export const JourneyNodeActionTypeEnum = {
  SendLineMessage: "send_line_message",
} as const;

const JourneyNodeActionTypeSchema = z.nativeEnum(JourneyNodeActionTypeEnum);
export type JourneyNodeActionType = z.infer<typeof JourneyNodeActionTypeSchema>;

export const JourneyNodeTypeEnum = {
  ...JourneyNodeTriggerTypeEnum,
  ...JourneyNodeRuleTypeEnum,
  ...JourneyNodeActionTypeEnum,
} as const;

export const JourneyNodeTypeSchema = z.nativeEnum(JourneyNodeTypeEnum);
export type JourneyNodeType = z.infer<typeof JourneyNodeTypeSchema>;

const featureSettingsSchema = z.object({
  webWidget: z.object({ level: z.enum(["on"]), policy: z.null() }),
  segmentSegmentExclusion: z.object({
    level: z.enum(["on", "off"]),
    policy: z.null(),
  }),
  segmentFilter: z.object({
    level: z.enum(["basic", "standard", "advanced"]),
    policy: z.array(
      z.object({
        category: CategorySchema,
        enabled: z.boolean(),
      }),
    ),
  }),
  segmentAvailableQuantity: z.object({
    level: z.union([z.coerce.number(), z.literal("unlimited")]),
    policy: z.null(),
  }),
  richMenuTriggerType: z.object({
    level: z.enum(["standard", "personalized"]),
    policy: z.array(
      z.object({ triggerType: TriggerTypeSchema, enabled: z.boolean() }),
    ),
  }),
  retargeting: z.object({ level: z.enum(["on", "off"]), policy: z.null() }),
  openApi: z.object({
    level: z.enum(["on", "off"]),
    policy: z.null(),
  }),
  journeyNode: z.object({
    level: z.enum(["basic", "standard", "advanced"]),
    policy: z.array(
      z.object({
        type: JourneyNodeTypeSchema,
        enabled: z.boolean(),
      }),
    ),
  }),
  journeyAvailableOngoingQuantity: z.object({
    level: z.union([z.coerce.number(), z.literal("unlimited")]),
    policy: z.null(),
  }),
  insight: z.object({ level: z.enum(["on"]), policy: z.null() }),
  dpm: z.object({ level: z.enum(["on", "off"]), policy: z.null() }),
  broadcastSmartSending: z.object({
    level: z.enum(["on", "off"]),
    policy: z.null(),
  }),
  accessManagement: z.object({
    level: z.enum(["on", "off"]),
    policy: z.null(),
  }),
});

export type FeatureSettings = z.infer<typeof featureSettingsSchema>;

const api = makeApi([
  {
    alias: "organizationSetting",
    method: "get",
    path: "/api/v1/organizations/setting",
    response: z.object({
      id: z.number().int().positive(),
      wccsAvailableWccsChannelNum: z.number(),
      wccsWebChatModuleEnabled: z.boolean(),
      wccsWebFootprintTrackingEnabled: z.boolean(),
      maacFeatureSettings: featureSettingsSchema,
    }),
  },
]);

export { api };
