import Stack from "@mui/material/Stack";
import { CAAC_URL } from "@polifonia/env";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Typography } from "@/components/Typography";
import CAAC_USERS_COVER_PATH from "@/statics/caacUsers.png";

export const CaacUsers: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="22px"
    >
      <img width={246} src={CAAC_USERS_COVER_PATH} alt="CAAC Users" />
      <Typography variant="body">
        {t("userManagement.caac.heroDescription")}
      </Typography>
      <Button
        variant="secondary"
        component="a"
        href={CAAC_URL}
        rel="noopener noreferrer"
      >
        {t("userManagement.caac.heroActionText")}
      </Button>
    </Stack>
  );
};
