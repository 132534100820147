import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { Overwrite } from "@mui/types";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { Tooltip } from "@/components/Tooltip";

type ButtonProps = React.ComponentProps<typeof Button>;

interface OwnProps {
  /**
   * The text to copy to clipboard
   * Can be either:
   * - A string value that will be directly copied
   * - A function that returns a Promise resolving to the string value to be copied
   */
  text: string | (() => Promise<string>);
}

type Props = Overwrite<Omit<ButtonProps, "onClick">, OwnProps>;

/**
 * A button that copies text on click, displays a success state, and optionally
 * allows a custom label; otherwise, it defaults to the “Copy” translation.
 */
export const CopyButton: React.FC<Props> = ({ text, ...props }) => {
  const { t } = useTranslation();
  const timer = useRef<ReturnType<typeof globalThis.setTimeout>>(null);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const doCopy = useHandler<ButtonProps["onClick"]>(
    async function doCopy(event) {
      event.stopPropagation();
      if (copied) return;

      copy(typeof text === "string" ? text : await text());

      setCopied(true);
      enqueueSnackbar(t("component.button.copy.success"), {
        variant: "success",
      });
      timer.current = globalThis.setTimeout(() => {
        setCopied(false);
      }, 2000);
    },
  );

  const defaultChildren = useMemo(
    () => <Trans i18nKey="component.button.copy" />,
    [],
  );

  return (
    <Tooltip
      title={t("component.button.copy.tooltip.title")}
      placement="right"
      arrow={true}
    >
      <Button
        startIcon={
          copied ? (
            <MotifIcon un-i-motif="check" />
          ) : (
            <MotifIcon un-i-motif="copy" />
          )
        }
        onClick={doCopy}
        {...{ children: defaultChildren }}
        {...props}
      />
    </Tooltip>
  );
};
