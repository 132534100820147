import { Stack } from "@mui/material";
import type { ErrorBoundaryProps, FallbackRender } from "@sentry/react";
import { useCallback } from "react";

import { Typography } from "@/components/Typography";
import { ErrorBoundary } from "@/layout/ErrorBoundary/ErrorBoundary";
import type { ErrorFallbackPageProps } from "@/layout/ErrorBoundary/ErrorFallbackPage";
import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage";
import { ErrorMsg } from "@/layout/ErrorBoundary/ErrorMsg";
import { EventId } from "@/layout/ErrorBoundary/EventId";

// This will expose the props to the component instead of using the `ErrorFallbackPageProps` prop.
type HoistedPropKey = "reloadWindow" | "css";

type PageErrorBoundaryProps = Omit<ErrorBoundaryProps, "fallback"> &
  Pick<ErrorFallbackPageProps, HoistedPropKey> & {
    ErrorFallbackPageProps?: Omit<
      ErrorFallbackPageProps,
      // These props will be passed in `fallback` function.
      | "errorData"
      | "description"
      // Hoisted props will be passed directly to the component.
      | HoistedPropKey
    >;
  };

const PageErrorBoundary: React.FC<PageErrorBoundaryProps> = ({
  children,
  ErrorFallbackPageProps,
  ...props
}) => {
  const fallback = useCallback<FallbackRender>(
    function fallback(errorData) {
      return (
        <ErrorFallbackPage
          {...ErrorFallbackPageProps}
          errorData={errorData}
          description={({ children }) => (
            <Stack gap="16px">
              {children}
              <Typography variant="body">
                <ErrorMsg error={errorData.error} />
              </Typography>
              <EventId eventId={errorData.eventId} />
            </Stack>
          )}
        />
      );
    },
    [ErrorFallbackPageProps],
  );
  return (
    <ErrorBoundary fallback={fallback} {...props}>
      {children}
    </ErrorBoundary>
  );
};

export { PageErrorBoundary };
