import { MaacPermissionSchema, UserSchema } from "@polifonia/interlude/models";
import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "myPermissions",
    method: "get",
    path: "/api/v1/users/permissions",
    response: z.object({
      email: z.string(),
      permissions: z.array(MaacPermissionSchema),
    }),
  },
  {
    alias: "users",
    method: "get",
    path: "/api/v1/users/list",
    parameters: [
      {
        type: "Query",
        name: "page",
        schema: z.number().optional(),
      },
      {
        type: "Query",
        name: "pageSize",
        schema: z.number().optional(),
      },
      {
        type: "Query",
        name: "searchFilter",
        schema: z.string().optional(),
      },
    ],
    response: z.array(UserSchema),
  },
  {
    alias: "availableRoles",
    method: "get",
    path: "/api/v1/organizations/roles",
    response: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
      }),
    ),
  },
  {
    alias: "updateUser",
    method: "patch",
    path: "/api/v1/users/update",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: z.object({
          email: z.string(),
          username: z.string(),
          roleIds: z.array(z.string()),
        }),
      },
    ],
    response: z.object({
      email: z.string(),
      username: z.string(),
      roles: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
        }),
      ),
    }),
  },
]);

export { api };
