// TODO: this component will be re-allocated to Motif repo in the future
import type { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { DistributiveOmit, Overwrite } from "@mui/types";
import { theme } from "@polifonia/theme";
import type { ComponentPropsWithRef, CSSProperties, ElementType } from "react";

type Variant = "h1" | "h2" | "h3" | "body" | "note" | "value";

interface TypographyProps {
  variant?: Variant;
  color?: CSSProperties["color"];
  fontWeight?: CSSProperties["fontWeight"] | "medium" | "regular";
}

const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  body: "p",
  note: "span",
  value: "span",
} as const satisfies Record<Variant, keyof React.JSX.IntrinsicElements>;

const defaultStyles = css`
  margin: 0;
`;

const variantStyles: Record<Variant, SerializedStyles> = {
  h1: css`
    font-size: 1.5rem;
    line-height: 1.16;
    color: ${theme.colors.staticFgTitle};
  `,
  h2: css`
    font-size: 1.125rem;
    line-height: 1.33;
    color: ${theme.colors.staticFgTitle};
  `,
  h3: css`
    font-size: 1rem;
    line-height: 1.5;
    color: ${theme.colors.staticFgTitle};
  `,
  body: css`
    font-size: 0.875rem;
    line-height: 1.42857;
    color: ${theme.colors.staticFgBody};
  `,
  note: css`
    font-size: 0.75rem;
    line-height: 1.33;
    color: ${theme.colors.staticFgNote};
  `,
  value: css`
    font-size: 2.25rem;
    line-height: 1.25;
  `,
};

type VariantToAs<T extends Variant> = (typeof variantMapping)[T];

type TypographyComponentProps<C extends ElementType> = TypographyProps &
  Omit<ComponentPropsWithRef<C>, keyof TypographyProps>;

const TypographyRoot = styled.span<TypographyProps>`
  ${defaultStyles}
  ${({ variant = "body" }) => variantStyles[variant]}
  color: ${({ color }) => color || undefined};
  font-weight: ${({ fontWeight }) => getFontWeight(fontWeight)};
`;

const typographyDefaultComponent = "span" as const;

namespace Typography {
  export type DefaultComponent = typeof typographyDefaultComponent;
  export type As = NonNullable<
    React.ComponentProps<typeof TypographyRoot>["as"]
  >;
  export interface OwnProps {
    as?: As;
    variant?: Variant;
  }
  export type Props<TComponent extends As = DefaultComponent> =
    TypographyComponentProps<TComponent>;
  export interface Type {
    <const TAs extends As>(
      props: Overwrite<Props<TAs>, { as: TAs; variant?: Variant }>,
    ): React.ReactNode;
    <const TVariant extends Variant>(
      props: Overwrite<
        DistributiveOmit<Props<VariantToAs<TVariant>>, "as">,
        { variant: TVariant }
      >,
    ): React.ReactNode;
    (props: DistributiveOmit<Props, "as" | "variant">): React.ReactNode;
  }
}

const TypographyInternal: Typography.Type = (props: Typography.Props) => {
  const variant: Variant = props.variant || "body";
  const Component: Typography.As = variantMapping[variant] || "span";

  return <TypographyRoot as={Component} variant={variant} {...props} />;
};

const Typography = Object.assign(TypographyInternal, {
  defaultComponent: typographyDefaultComponent,
});

function getFontWeight(weight: TypographyProps["fontWeight"] = "inherit") {
  switch (weight) {
    case "medium":
      return 500;
    case "regular":
      return 400;
    default:
      return weight;
  }
}

export { Typography };
