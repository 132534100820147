import { ChannelSchema } from "@polifonia/interlude/models";
import { OrgSourceSchema } from "@polifonia/interlude/models";
import { NumberStringSchema } from "@polifonia/utils/zod/NumberStringSchema";
import { createRootRoute, createRoute } from "@tanstack/react-router";
import { z } from "zod";

import { ErrorFallbackPage } from "@/layout/ErrorBoundary/ErrorFallbackPage/OldErrorFallbackPage";
import { tokenSearchSchema } from "@/layout/types";
import { ApplicationPage } from "@/pages/applications";
import { CdhPage } from "@/pages/applications/pages/cdh";
import { BillingPage } from "@/pages/billing";
import { ChannelsPage } from "@/pages/channels";
import { ChannelDetailPage } from "@/pages/channels/pages/detail";
import { RootComponent } from "@/pages/RootComponent";
import { UserManagementPage } from "@/pages/userManagement";

const rootRoute = createRootRoute({
  validateSearch: tokenSearchSchema,
  component: () => <RootComponent />,
  notFoundComponent: () => <RootComponent />,
  errorComponent: (props: { error: Error }) => (
    <ErrorFallbackPage
      resetErrorBoundary={() => {}}
      error={props.error}
      reloadWindow
    />
  ),
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => <ApplicationPage />,
});

const applicationRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications",
  component: () => <ApplicationPage />,
});

const cdhRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/applications/cdh",
  component: () => <CdhPage />,
});

const billingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/billing",
  component: () => <BillingPage />,
});

const channelRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/channels",
  component: ChannelsPage,
});

const channelDetailRouteSchema = z.object({
  type: ChannelSchema.shape.type,
  id: z.union([NumberStringSchema, z.string().uuid()]),
});

const channelDetailRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "channels/$type/$id",
  params: {
    parse: channelDetailRouteSchema.parse,
  },
  errorComponent: ChannelsPage,
  component: ChannelDetailPage,
});

const userManagementRoute = createRoute({
  validateSearch: z
    .object({
      "target-edit-user-email": z.string().optional(),
    })
    .and(
      z.object({
        tab: OrgSourceSchema.optional(),
      }),
    ),
  getParentRoute: () => rootRoute,
  path: "/user-management",
  component: UserManagementPage,
});

export const routeTree = rootRoute.addChildren([
  applicationRoute,
  billingRoute,
  cdhRoute,
  channelRoute,
  channelDetailRoute,
  userManagementRoute,
  indexRoute,
]);
