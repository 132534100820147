import { makeApi } from "@zodios/core";
import { z } from "zod";

import {
  ChannelSchema,
  ChannelWithInformationSchema,
  WccsChannelSchema,
} from "../models";

const CreateChannelBaseRequestSchema = z.object({
  type: z.enum(["wccs", "whatsapp"]),
});

const CreateWccsChannelRequestSchema = CreateChannelBaseRequestSchema.extend({
  name: ChannelSchema.shape.name,
  type: z.literal("wccs"),
  externalChannelId: z.string().nullable(),
  externalChannelSecret: z.string().nullable(),
  externalChannelAccessToken: z.string().nullable(),
});

const CreateWhatsappChannelRequestSchema =
  CreateChannelBaseRequestSchema.extend({
    type: z.literal("whatsapp"),
    externalChannelId: z.string(),
    extra: z.object({
      signupAuthCode: z.string(),
      wabaId: z.string(),
    }),
  });

const CreateChannelRequestSchema = z.discriminatedUnion("type", [
  CreateWccsChannelRequestSchema,
  CreateWhatsappChannelRequestSchema,
]);

const UpdateChannelBaseRequestSchema = z
  .object({
    name: ChannelSchema.shape.name,
    status: ChannelSchema.shape.status,
  })
  .partial();

const UpdateWccsChannelRequestSchema = UpdateChannelBaseRequestSchema.extend({
  externalChannelSecret:
    CreateWccsChannelRequestSchema.shape.externalChannelSecret,
  externalChannelAccessToken:
    CreateWccsChannelRequestSchema.shape.externalChannelAccessToken,
  channelInformation: WccsChannelSchema.shape.channelInformation
    .pick({
      webTrackingEnabled: true,
      webChatModuleEnabled: true,
    })
    .partial(),
}).partial();

const UpdateWhatsappChannelRequestSchema =
  UpdateChannelBaseRequestSchema.partial();

const api = makeApi([
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#2001f5d24ba14739b09f21f2be431d3c)
     */
    alias: "channels",
    method: "get",
    path: "/api/v1/channels/",
    response: z.object({
      channels: ChannelWithInformationSchema.array(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#bda29b988f164337b7c94dd976a9ccb8)
     */
    alias: "createChannel",
    method: "post",
    path: "/api/v1/channels/",
    parameters: [
      {
        type: "Body",
        name: "body",
        schema: CreateChannelRequestSchema,
      },
    ],
    response: ChannelWithInformationSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/202502-WhatsApp-admin-center-d24471c24b304e329fc656263c68b80a?pvs=4#1988ce15893880be8ba0caf4e2a5b89b)
     */
    alias: "reauthorizeChannel",
    method: "post",
    path: "/api/v1/channels/:channelId/authorize",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          type: z.literal("whatsapp"),
          extra: z.object({
            signupAuthCode: z.string(),
          }),
        }),
      },
    ],
    response: ChannelWithInformationSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#4a0dbcb4326544b9b3076f2cba1c206f)
     */
    alias: "getById",
    method: "get",
    path: "/api/v1/channels/:channelId",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
    ],
    response: ChannelWithInformationSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#927752d3636843ee9061be8d89f23029)
     */
    alias: "updateChannel",
    method: "patch",
    path: "/api/v1/channels/:channelId",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.union([
          UpdateWccsChannelRequestSchema,
          UpdateWhatsappChannelRequestSchema,
        ]),
      },
    ],
    response: ChannelWithInformationSchema,
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#e2aea983e1a7423c8b4a080adebd9325)
     */
    alias: "addChannelDomain",
    method: "post",
    path: "/api/v1/channels/:channelId/domains",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          url: z.string(),
        }),
      },
    ],
    response: z.object({
      id: z.number().int().positive(),
      url: z.string(),
    }),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#e12ee59f3ba44ed9a4ca3d6d7d5bdeb8)
     */
    alias: "updateChannelDomain",
    method: "put",
    path: "/api/v1/channels/:channelId/domains/:domainId",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
      {
        type: "Path",
        name: "domainId",
        schema: z.number().int(),
      },
      {
        type: "Body",
        name: "body",
        schema: z.object({
          url: z.string(),
        }),
      },
    ],
    response: z.unknown(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#4b8ced516d2a459994ffbb5560fb6b10)
     */
    alias: "deleteChannelDomain",
    method: "delete",
    path: "/api/v1/channels/:channelId/domains/:domainId",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
      {
        type: "Path",
        name: "domainId",
        schema: z.number().int(),
      },
    ],
    response: z.unknown(),
  },
  {
    /**
     * Spec: [Notion](https://www.notion.so/cresclab/Web-SDK-P1-84374da2c0e5459dba58d84c18c06233?pvs=4#4a82385ed2d9472aaca0c0b6fa071cab)
     */
    alias: "getTrackingConnectionStatus",
    method: "get",
    path: "/api/v1/channels/:channelId/tracking-connection-status",
    parameters: [
      {
        type: "Path",
        name: "channelId",
        schema: ChannelSchema.shape.id,
      },
    ],
    response: z.object({
      status: ChannelSchema.shape.status,
    }),
  },
]);

export { api };
