import { typedEntries } from "@chatbotgang/etude/object/typedEntries";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import type { TabsProps } from "@mui/material/Tabs";
import Tabs from "@mui/material/Tabs";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import type { FC } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/Badge";
import type { InterludeTypes } from "@/interlude";
import { PageLayout } from "@/pages/components/PageLayout";
import { PageTitle } from "@/pages/components/PageTitle";
import { CaacUsers } from "@/pages/userManagement/components/CaacUsers";
import { MaacUsers } from "@/pages/userManagement/components/MaacUsers";

const routeApi = getRouteApi("/user-management");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`user-management-tabpanel-${index}`}
      aria-labelledby={`user-management-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `user-management-tab-${index}`,
    "aria-controls": `user-management-tabpanel-${index}`,
  };
}

const orgSourceTabValue = {
  maac: 0,
  caac: 1,
} satisfies Record<InterludeTypes["OrgSource"], number>;

export const UserManagementPage: FC = () => {
  const { t } = useTranslation();
  const { tab } = routeApi.useSearch();
  const navigate = useNavigate();
  const tabValue = useMemo(() => orgSourceTabValue[tab ?? "maac"], [tab]);
  const handleChange = useHandler<TabsProps["onChange"]>(
    (_, newValue: number) => {
      navigate({
        to: "/user-management",
        search: {
          tab: typedEntries(orgSourceTabValue).find(
            ([_, value]) => value === newValue,
          )?.[0],
        },
      });
    },
  );
  const [maacUserCount, setMaacUserCount] = useState(0);

  return (
    <PageLayout>
      <PageTitle>{t("userManagement.title")}</PageTitle>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab
          label={
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {t("userManagement.source.maac")}
              <Badge variant="superior">{maacUserCount}</Badge>
            </Stack>
          }
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              {t("userManagement.source.caac")}
            </Stack>
          }
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <MaacUsers setMaacUserCount={setMaacUserCount} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CaacUsers />
      </TabPanel>
    </PageLayout>
  );
};
