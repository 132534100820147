import { z } from "zod";

export const ErrorSchema = z.object({
  message: z.string(),
  code: z.enum([
    "UNAUTHORIZED",
    "CHANNEL_WCCS_INFORMATION_URL_ALREADY_EXISTS",
    "ORGANIZATION_NOT_FOUND",
    "BALANCE_INSUFFICIENT",
    "SUBSCRIPTION_NOT_FOUND",
    "MONTHLY_USAGE_REPORT_NOT_FOUND",
    "UNIFY_SCOPE_SETTING_NOT_FOUND",
    "INTEGRATE_CDH_FAILED",
    "UNIFY_KEY_UPDATE_FAIL",
    "DISCONNECT_CDH_FAILED",
    "GET_MERGE_STATE_FAILED",
    "SERVE_CONTACT_UPDATE_EVENT_FAILED",
    "GET_ORGANIZATIONS_FAILED",
    "CHANNEL_NOT_FOUND",
    "CHANNEL_WCCS_INFORMATION_NOT_FOUND",
    "ORGANIZATION_SETTING_NOT_FOUND",
    "UNKNOWN_ERROR",
  ]),
});

export const UnificationKeysSchema = z.enum([
  "display_email",
  "custom_id",
  "connect_id",
  "display_mobile",
  "line_id",
]);

export const OrgSourceSchema = z.enum(["maac", "caac"]);

export const OrgSchema = z.object({
  id: z.number().int(),
  source: OrgSourceSchema,
  name: z.string(),
  uuid: z.string(),
});

export const UnifyScopeSettingSchema = z.object({
  unifyScopeId: z.number().int(),
  isCdhConnected: z.boolean(),
  orgs: OrgSchema.array(),
  unifyKeys: z
    .object({
      attribute: UnificationKeysSchema,
      isDistinct: z.boolean(),
    })
    .array(),
});

export const ChannelSchema = z.object({
  id: z.number().int().positive(),
  organizationId: z.number().int().positive(),
  uuid: z.string(),
  name: z.string(),
  type: z.enum(["wccs", "whatsapp", "line"]),
  status: z.enum(["connected", "disconnected"]),
  externalChannelId: z.string().nullable(),
});

export const WccsChannelSchema = ChannelSchema.extend({
  type: z.literal("wccs"),
  channelInformation: z.object({
    webChatModuleEnabled: z.boolean(),
    webTrackingEnabled: z.boolean(),
    domains: z
      .object({
        id: z.number().int().positive(),
        url: z.string(),
      })
      .array(),
  }),
});

export const WhatsappChannelSchema = ChannelSchema.extend({
  type: z.literal("whatsapp"),
  channelInformation: z.object({
    phoneNumber: z.string(),
    wabaInformation: z
      .object({
        name: z.string(),
        wabaId: z.string(),
      })
      // TODO: Remove this once the backend is updated
      .nullable(),
  }),
});

export const LineChannelSchema = ChannelSchema.extend({
  type: z.literal("line"),
  channelInformation: z.object({}),
});

export const ChannelWithInformationSchema = z.discriminatedUnion("type", [
  WccsChannelSchema,
  WhatsappChannelSchema,
  LineChannelSchema,
]);

export const MaacRoleNameSchema = z
  .custom<`maac:${string}`>()
  .refine((value) => {
    return value.startsWith("maac:");
  });

export type MaacRoleName = z.infer<typeof MaacRoleNameSchema>;

export const MaacPermissionSchema = z.enum([
  "maac:user_roles:unassign",
  "maac:user_roles:assign",
  "maac:contact:unmask",
  "maac:export_contact:unmask",
]);

export type MaacPermission = z.infer<typeof MaacPermissionSchema>;

export const UserSchema = z.object({
  username: z.string().nullable(),
  email: z.string(),
  roles: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
});
