import { makeApi } from "@zodios/core";
import { z } from "zod";

const api = makeApi([
  {
    alias: "login",
    method: "post",
    path: "/api/v1/auth/login",
    parameters: [
      {
        type: "Body",
        name: "token",
        schema: z.object({
          token: z.string(),
        }),
      },
    ],
    response: z.object({
      token: z.string(),
    }),
  },
  {
    alias: "getMe",
    method: "get",
    path: "/api/v1/auth/me",
    response: z.object({
      organization: z.object({
        uuid: z.string(),
        name: z.string(),
        releaseTier: z.enum(["1st_tier", "2nd_tier", "3rd_tier"]),
      }),
    }),
  },
]);

export { api };
