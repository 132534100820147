import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import type { FallbackRender } from "@sentry/react";

import { CopyInput } from "@/components/Input/CopyInput";
import { defineStyles } from "@/internal/emotion";

type ErrorData = Parameters<FallbackRender>[0];
interface OwnProps {
  eventId: ErrorData["eventId"];
}
type EventIdProps = Overwrite<ComponentProps<"label">, OwnProps>;

const cssEventId = defineStyles({
  root: css({
    display: "flex",
    alignItems: "center",
    fontSize: "0.75rem",
    gap: "8px",
    whiteSpace: "nowrap",
  }),
  label: css({
    userSelect: "none",
  }),
  input: css({
    flex: 1,
    padding: 0,
    borderColor: "transparent",
    backgroundColor: "transparent",
    color: "inherit",
  }),
});

const EventId: React.FC<EventIdProps> = ({ eventId, ...props }) => {
  return (
    <label css={cssEventId.root} {...props}>
      <div css={cssEventId.label}>Event ID: </div>
      <CopyInput css={cssEventId.input} value={eventId} />
    </label>
  );
};

export { EventId };
