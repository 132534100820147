import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import { Link, useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { ArrayValues } from "type-fest";

import { Button } from "@/components/Button";
import { Empty } from "@/components/Empty";
import { MotifIcon } from "@/components/MotifIcon";
import { TableRowsLoader } from "@/components/TableRowsLoader";
import { Typography } from "@/components/Typography";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";
import { ChannelIcon } from "@/pages/channels/components/ChannelIcon";
import { ChannelSelectModal } from "@/pages/channels/components/ChannelSelectModal";
import { ChannelStatus } from "@/pages/channels/components/ChannelStatus";
import { WccsActivation } from "@/pages/channels/components/WccsActivation";
import { WccsAddLimitModal } from "@/pages/channels/components/WccsAddLimitModal";
import { WccsPlanExpiredModal } from "@/pages/channels/components/WccsPlanExpiredModal";
import { useWhatsappLogin } from "@/pages/channels/hooks/useWhatsappLogin";
import { FailedConnectModal } from "@/pages/channels/pages/detail/whatsapp/components/FailedConnectModal";
import { PageLayout } from "@/pages/components/PageLayout";
import { PageTitle } from "@/pages/components/PageTitle";

const MAX_WCCS_CHANNEL_COUNT = 1;

const channelOrder = ["line", "whatsapp", "wccs"] as const satisfies Array<
  InterludeTypes["Channel"]["type"]
>;

if (!import.meta.env.PROD) {
  const anyForAssertion: any = undefined;
  // To ensure that the `typesOrder` array contains all the channel types.
  anyForAssertion as Array<InterludeTypes["Channel"]["type"]> satisfies Array<
    ArrayValues<typeof channelOrder>
  >;
}

/**
 * Sorts channels by:
 * 1. Connection status (connected channels first)
 * 2. Channel type according to predefined order
 * 3. Channel name alphabetically
 *
 * @param channels - Array of channels to sort
 * @returns Sorted array of channels
 */
const sortChannels = <
  TChannel extends Pick<InterludeTypes["Channel"], "status" | "type" | "name">,
>(
  channels: Array<TChannel>,
) => {
  return [...channels].sort((a, b) => {
    if (a.status !== b.status) {
      return a.status === "connected" ? -1 : 1;
    }

    const typeOrderDiff =
      channelOrder.indexOf(a.type) - channelOrder.indexOf(b.type);
    if (typeOrderDiff !== 0) {
      return typeOrderDiff;
    }

    return a.name.localeCompare(b.name);
  });
};

const styles = defineStyles({
  emptyRow: css({ height: "180px" }),
  empty: css({
    minHeight: "64px",
  }),
  ChannelEditButton: css({
    padding: 6,
    minWidth: "auto",
    fontSize: 16,
    color: theme.colors.buttonPlainDefault,
  }),
});

const ChannelEditButton: React.FC<{
  channel: Pick<InterludeTypes["Channel"], "id" | "type">;
}> = ({ channel }) => {
  const [open, toggle] = useSwitch();
  const organizationSettingQuery =
    interlude.organization.useOrganizationSetting(undefined, {
      enabled: channel.type === "wccs",
    });

  const canUseWccsChannel = useMemo(() => {
    return Boolean(organizationSettingQuery.data?.wccsWebChatModuleEnabled);
  }, [organizationSettingQuery.data]);

  if (channel.type === "wccs" && !canUseWccsChannel) {
    return (
      <>
        <Button
          variant="plain"
          css={styles.ChannelEditButton}
          onClick={toggle.on}
        >
          <MotifIcon un-i-motif="edit" />
        </Button>
        <WccsPlanExpiredModal open={open} handleClose={toggle.off} />
      </>
    );
  }

  return (
    <Link
      to="/channels/$type/$id"
      params={{
        type: channel.type,
        id: channel.id,
      }}
    >
      <Button variant="plain" css={styles.ChannelEditButton}>
        <MotifIcon un-i-motif="edit" />
      </Button>
    </Link>
  );
};

export const ChannelsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const channelsQuery = interlude.channel.useChannels();
  const channels = useMemo(
    () =>
      !channelsQuery.data ? [] : sortChannels(channelsQuery.data.channels),
    [channelsQuery.data],
  );
  const [addChannelModalOpen, toggleAddChannelModalOpen] = useSwitch(false);
  const [addWccsChannelLimitModalOpen, toggleAddWccsChannelLimitModalOpen] =
    useSwitch(false);

  const organizationSetting = interlude.organization.useOrganizationSetting();

  const navigateToChannel = (channel: InterludeTypes["Channel"]) => {
    navigate({
      to: "/channels/$type/$id",
      params: {
        type: channel.type,
        id: channel.id,
      },
    });
  };

  const [openFailedConnectModal, toggleFailedConnectModal] = useSwitch();

  const createMutation = interlude.channel.useCreateChannel(undefined, {
    onSuccess: (channel) => {
      navigateToChannel(channel);
    },
    onError: () => {
      toggleFailedConnectModal.on();
    },
  });

  const loginWhatsappMutation = useWhatsappLogin({
    onSuccess: ({ wabaId, phoneNumberId, signupAuthCode }) => {
      createMutation.mutate({
        type: "whatsapp",
        externalChannelId: phoneNumberId,
        extra: {
          wabaId,
          signupAuthCode,
        },
      });
    },
    onError: () => {
      toggleFailedConnectModal.on();
    },
  });

  const handleAddChannelContinue = async (
    selectedChannel: InterludeTypes["Channel"]["type"],
  ) => {
    if (selectedChannel === "wccs") {
      if (
        organizationSetting.data?.wccsAvailableWccsChannelNum ===
          MAX_WCCS_CHANNEL_COUNT &&
        channels.some((channel) => channel.type === "wccs")
      ) {
        toggleAddWccsChannelLimitModalOpen();
        toggleAddChannelModalOpen();
        return;
      }

      if (createMutation.isPending) return;
      createMutation.mutate({
        name: "Web Channel",
        type: "wccs",
        externalChannelId: null,
        externalChannelSecret: null,
        externalChannelAccessToken: null,
      });
    }

    if (selectedChannel === "whatsapp") {
      loginWhatsappMutation.startLogin();
    }
  };

  const canUseWccsChannel = useMemo(() => {
    return Boolean(organizationSetting.data?.wccsWebChatModuleEnabled);
  }, [organizationSetting.data]);

  return (
    <PageLayout>
      <PageTitle>{t("channels.title")}</PageTitle>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        css={css({ marginBottom: 24, alignItems: "center" })}
      >
        <Typography variant="body" color={theme.colors.staticFgBody}>
          {t("channels.description")}
        </Typography>
        <Button onClick={toggleAddChannelModalOpen}>
          {t("channels.button.create")}
        </Button>
      </Grid>
      <TableContainer
        sx={{
          height: "calc(100vh - 200px)",
          overflowX: "auto",
        }}
      >
        <Table aria-label="channel table" stickyHeader sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell width={400}>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.name")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.status")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("channels.table.column.activation")}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {channelsQuery.isPending ? <TableRowsLoader rowsNum={5} /> : null}
            {!channelsQuery.isPending ? (
              channels.length === 0 ? (
                <TableRow css={styles.emptyRow}>
                  <TableCell colSpan={3} align="center">
                    <Stack gap="12px">
                      <Empty css={styles.empty} />
                      <Typography
                        variant="body"
                        fontWeight={400}
                        color={theme.colors.staticFgNote}
                      >
                        {t("channels.table.empty")}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : (
                channels.map((channel) => (
                  <TableRow key={channel.id}>
                    <TableCell component="th" scope="row">
                      <Stack
                        direction="row"
                        spacing="10px"
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <ChannelIcon type={channel.type} />
                        <Stack>
                          <Typography
                            variant="body"
                            fontWeight={400}
                            color={theme.colors.staticFgTitle}
                          >
                            {channel.name}
                          </Typography>
                          {channel.type === "wccs" ? (
                            channel.channelInformation.domains.length > 0 ? (
                              channel.channelInformation.domains.map(
                                (domain) => (
                                  <Typography
                                    key={domain.id}
                                    variant="note"
                                    fontWeight={500}
                                    color={theme.colors.staticFgNote}
                                  >
                                    {domain.url}
                                  </Typography>
                                ),
                              )
                            ) : (
                              <Typography
                                variant="note"
                                fontWeight={500}
                                color={theme.colors.staticFgNote}
                              >
                                {t("channels.table.column.name.domains.empty")}
                              </Typography>
                            )
                          ) : null}
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <ChannelStatus status={channel.status} />
                    </TableCell>
                    <TableCell>
                      {channel.type === "wccs" && canUseWccsChannel ? (
                        <WccsActivation
                          webTrackingEnabled={
                            channel.channelInformation?.webTrackingEnabled
                          }
                          webChatModuleEnabled={
                            channel.channelInformation?.webChatModuleEnabled
                          }
                        />
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <ChannelEditButton channel={channel} />
                    </TableCell>
                  </TableRow>
                ))
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <ChannelSelectModal
        loading={createMutation.isPending}
        open={addChannelModalOpen}
        onClose={toggleAddChannelModalOpen}
        onSubmit={handleAddChannelContinue}
        wccsAvailableChannelCount={
          organizationSetting.data?.wccsAvailableWccsChannelNum ?? 0
        }
      />
      <WccsAddLimitModal
        open={addWccsChannelLimitModalOpen}
        onClose={toggleAddWccsChannelLimitModalOpen}
      />
      <FailedConnectModal
        open={openFailedConnectModal}
        onClose={toggleFailedConnectModal.off}
        onConfrim={toggleFailedConnectModal.off}
      />
    </PageLayout>
  );
};
