import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@polifonia/theme";
import type { ReactNode } from "react";
import { useMemo } from "react";

import { NewWindowIcon } from "@/components/Icons/NewWindowIcon";
import { cssOneLine } from "@/internal/emotion";

type ExternalLinkProps = Overwrite<
  React.ComponentProps<"a">,
  {
    href: string;
    trailingIcon?: ReactNode | true;
    noWrap?: boolean;
    disabled?: boolean;
  }
>;

type ExternalLinkType = (props: ExternalLinkProps) => ReactNode;

const cssBase = css`
  display: inline-flex;
  gap: 0.25em;
  color: ${theme.colors.blue060};
  align-items: center;

  :not(:hover, :active) {
    text-decoration-line: none;
  }

  &:focus {
    outline: ${theme.colors.blue060} auto 1px;
  }

  &:hover {
    color: ${theme.colors.blue050};
  }

  &:active {
    color: ${theme.colors.blue070};
  }
`;

const cssNoWrap = css`
  ${cssOneLine}
`;

/**
 * This component is under TBD for overflow handling.
 */
const ExternalLink: ExternalLinkType = ({
  children,
  trailingIcon,
  noWrap,
  disabled,
  ...props
}) => {
  const mergedCss = useMemo(
    function mergedCss() {
      return css(cssBase, noWrap && cssNoWrap);
    },
    [noWrap],
  );
  const tabIndex = "tabIndex" in props ? props.tabIndex : 0;
  return (
    <a
      css={mergedCss}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      tabIndex={tabIndex}
    >
      <span>{children}</span>
      <span css={css({ display: "inline-flex", fontSize: "1.14em" })}>
        {!trailingIcon ? null : trailingIcon === true ? (
          <NewWindowIcon fontSize="inherit" />
        ) : (
          trailingIcon
        )}
      </span>
    </a>
  );
};

export { ExternalLink };
export type { ExternalLinkProps, ExternalLinkType };
