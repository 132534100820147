import { parseJson } from "@crescendolab/parse-json";
import { WHATSAPP_CONFIG_ID } from "@polifonia/env";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";

import { fbSdk } from "@/lib/fbSdk";

const CustomerInfoSchema = z.object({
  waba_id: z.string(),
  phone_number_id: z.string(),
});

type CustomerInfo = z.infer<typeof CustomerInfoSchema>;

const EmbeddedSignupBaseSchema = z.object({
  type: z.literal("WA_EMBEDDED_SIGNUP"),
  version: z.string(),
});

const EmbeddedSignupSchema = z.discriminatedUnion("event", [
  EmbeddedSignupBaseSchema.extend({
    event: z.literal("FINISH"),
    data: CustomerInfoSchema,
  }),
  EmbeddedSignupBaseSchema.extend({
    event: z.literal("CANCEL"),
    data: z.object({
      current_step: z.string(),
    }),
  }),
]);

type UseWhatsappLoginOptions = {
  onError?: (error?: Error) => void;
  onSuccess?: ({
    wabaId,
    phoneNumberId,
    signupAuthCode,
  }: {
    wabaId: string;
    phoneNumberId: string;
    signupAuthCode: string;
  }) => void;
};

export function useWhatsappLogin(options?: UseWhatsappLoginOptions) {
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();

  const handleError = useCallback(
    (error?: Error) => {
      options?.onError?.(error);
    },
    [options],
  );

  const loginMutation = fbSdk.hooks.mutations.useLoginMutation({
    onSuccess: async (data) => {
      if (
        data.status !== "connected" ||
        !data.authResponse?.code ||
        !customerInfo
      ) {
        handleError();
        return;
      }

      options?.onSuccess?.({
        wabaId: customerInfo.waba_id,
        phoneNumberId: customerInfo.phone_number_id,
        signupAuthCode: data.authResponse.code,
      });
    },
    onError: handleError,
  });

  // https://developers.facebook.com/docs/whatsapp/embedded-signup/implementation/#session-logging-message-event-listener
  useEffect(() => {
    const handleWhatsappEmbeddedSignupMessage = (event: MessageEvent) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return;
      }

      const parsedData = parseJson(event.data, { fallback: null });

      if (!parsedData) {
        return;
      }

      const result = EmbeddedSignupSchema.safeParse(parsedData);

      if (!result.success) {
        handleError();
        return;
      }

      if (
        result.data.type === "WA_EMBEDDED_SIGNUP" &&
        result.data.event === "FINISH"
      ) {
        setCustomerInfo({
          waba_id: result.data.data.waba_id,
          phone_number_id: result.data.data.phone_number_id,
        });
      }
    };

    window.addEventListener("message", handleWhatsappEmbeddedSignupMessage);
    return () =>
      window.removeEventListener(
        "message",
        handleWhatsappEmbeddedSignupMessage,
      );
  }, [handleError]);

  const startLogin = ({
    extras,
  }: {
    extras?: facebook.LoginOptions["extras"];
  } = {}) => {
    loginMutation.mutate({
      loginOptions: {
        config_id: WHATSAPP_CONFIG_ID,
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
          featureType: "",
          sessionInfoVersion: "3",
          ...extras,
        },
      },
    });
  };

  return {
    startLogin,
    isPending: loginMutation.isPending,
  };
}
