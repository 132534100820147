import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { createQueriesContext } from "@polifonia/utils/react-query/createQueriesContext";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

import { ResourceNotFoundError } from "@/features/error";
import { interlude } from "@/interlude";

const route = getRouteApi("/channels/$type/$id");

const useChannelQueries = () => {
  const { id } = route.useParams();
  const navigate = useNavigate();

  const channelsQuery = interlude.channel.useChannels(undefined, {
    enabled: typeof id === "string",
    select(data) {
      return data.channels;
    },
  });

  useEffect(
    function navigateChannelUuid() {
      if (typeof id === "number") return;
      if (!channelsQuery.isSuccess) return;

      const channel = channelsQuery.data.find((channel) => channel.uuid === id);
      if (!channel) {
        throw new ResourceNotFoundError(
          inspectMessage`Channel with uuid ${id} not found`,
        );
      }
      navigate({ to: `/channels/${channel.type}/${channel.id}` });
    },
    [channelsQuery.isSuccess, channelsQuery.data, id, navigate],
  );

  const channelQuery = interlude.channel.useSuspenseGetById({
    params: {
      channelId: typeof id === "string" ? Number.NaN : id,
    },
  });
  return {
    channel: channelQuery,
  };
};

const ChannelQueriesContext =
  createQueriesContext<ReturnType<typeof useChannelQueries>>();

export { ChannelQueriesContext, useChannelQueries };
