import EventEmitter from "eventemitter3";

import type { ApiErrorResponseData } from "@/features/error/handleError";

export type MutationErrorEvents = {
  error: (err: unknown) => void;
  knownError: (err: ApiErrorResponseData) => void;
};

export const mutationErrorEventEmitter =
  new EventEmitter<MutationErrorEvents>();
