// cspell:words fbtrace, xfbml
import useEventCallback from "@mui/utils/useEventCallback";
import {
  type MutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from "@tanstack/react-query";
import axios from "axios";
import { nanoid } from "nanoid";
import { z } from "zod";
import { persist } from "zustand/middleware";
import { createWithEqualityFn } from "zustand/traditional";

const FbSdkErrSchema = z.object({
  error: z.object({
    code: z.number(),
    fbtrace_id: z.string(),
    message: z.string(),
    type: z.string(),
  }),
});

type FbSdkErr = z.infer<typeof FbSdkErrSchema>;

const FbLoginStatusSchema = z.enum([
  "authorization_expired",
  "connected",
  "not_authorized",
  "unknown",
]);

/**
 * @see {@link facebook.AuthResponse}
 */
const AuthResponseSchema = z
  .object({
    accessToken: z.string().optional(),
    data_access_expiration_time: z.number().optional(),
    expiresIn: z.number(),
    signedRequest: z.string().optional(),
    userID: z.string(),
    grantedScopes: z.string().optional(),
    reauthorize_required_in: z.number().optional(),
    code: z.string().optional(),
  })
  .passthrough()
  .or(
    /**
     * response_type: "code"
     */
    z.object({
      code: z.string(),
      expiresIn: z.nan().nullable(),
      userID: z.null(),
    }),
  );

const StatusResponseSchema = z
  .object({
    status: FbLoginStatusSchema,
    authResponse: AuthResponseSchema.nullable(),
  })
  .passthrough();

const UserSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    picture: z
      .object({
        data: z.object({
          height: z.number(),
          is_silhouette: z.boolean(),
          url: z.string(),
          width: z.number(),
        }),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();

const InstagramUserSchema = UserSchema.extend({
  name: z.string(),
  username: z.string(),
  profile_picture_url: z.string().optional(),
}).passthrough();

/**
 * https://developers.facebook.com/docs/graph-api/results#cursors
 */
function CursorBasedPaginationSchema<D extends z.ZodType>(DataSchema: D) {
  return z
    .object({
      data: z.array(DataSchema),
      paging: z
        .object({
          previous: z.string().optional(),
          next: z.string().optional(),
        })
        .passthrough()
        .optional(),
    })
    .passthrough();
}

const AccountSchema = UserSchema.extend({
  access_token: z.string(),
  instagram_business_account: z
    .object({
      id: z.string(),
    })
    .passthrough()
    .optional(),
}).passthrough();

const BusinessSchema = z
  .object({
    id: z.string(),
    name: z.string(),
  })
  .passthrough();

/**
 * - [Docs](https://developers.facebook.com/docs/marketing-api/reference/business/owned_whatsapp_business_accounts/)
 */
const WhatsappBusinessAccountSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    timezone_id: z.string(),
    message_template_namespace: z.string(),
  })
  .passthrough();

const useFbSdkLoadedStore = createWithEqualityFn<boolean>()(
  () => "FB" in window,
);
function useFbSdkLoaded() {
  return useFbSdkLoadedStore();
}
window.fbAsyncInit = function fbAsyncInit() {
  useFbSdkLoadedStore.setState(true);
};

const useFbInitParamsStore = createWithEqualityFn<facebook.InitParams>()(
  persist<facebook.InitParams>(
    () => ({
      appId: "",
      xfbml: true,
      version: "v22.0",
    }),
    {
      name: "fb-sdk-fb-init-params",
    },
  ),
);

/**
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/451dc8f/types/facebook-js-sdk/index.d.ts#L94
 */
interface Me<TParam extends facebook.UserField> {
  id: number;
  about?: TParam extends "about" ? string : never | undefined | undefined;
  age_range?: TParam extends "age_range"
    ? facebook.AgeRange
    : never | undefined | undefined;
  birthday?: TParam extends "birthday" ? string : never | undefined | undefined;
  education?: TParam extends "education"
    ? Array<facebook.EducationExperience>
    : never | undefined | undefined;
  email?: TParam extends "email" ? string : never | undefined | undefined;
  favorite_athletes?: TParam extends "favorite_athletes"
    ? Array<facebook.Experience>
    : never | undefined | undefined;
  favorite_teams?: TParam extends "favorite_teams"
    ? Array<facebook.Experience>
    : never | undefined | undefined;
  first_name?: TParam extends "first_name"
    ? string
    : never | undefined | undefined;
  gender?: TParam extends "gender" ? string : never | undefined | undefined;
  hometown?: TParam extends "hometown"
    ? facebook.Page
    : never | undefined | undefined;
  inspirational_people?: TParam extends "inspirational_people"
    ? Array<facebook.Experience>
    : never | undefined | undefined;
  install_type?: TParam extends "install_type"
    ? unknown
    : never | undefined | undefined;
  is_guest_user?: TParam extends "is_guest_user"
    ? boolean
    : never | undefined | undefined;
  languages?: TParam extends "languages"
    ? Array<facebook.Experience>
    : never | undefined | undefined;
  last_name?: TParam extends "last_name"
    ? string
    : never | undefined | undefined;
  link?: TParam extends "link" ? string : never | undefined | undefined;
  location?: TParam extends "location"
    ? facebook.Page
    : never | undefined | undefined;
  meeting_for?: TParam extends "meeting_for"
    ? Array<string>
    : never | undefined | undefined;
  middle_name?: TParam extends "middle_name"
    ? string
    : never | undefined | undefined;
  name?: TParam extends "name" ? string : never | undefined | undefined;
  name_format?: TParam extends "name_format"
    ? string
    : never | undefined | undefined;
  payment_pricepoints?: TParam extends "payment_pricepoints"
    ? facebook.PaymentPricepoints
    : never | undefined | undefined;
  name_political?: TParam extends "political"
    ? string
    : never | undefined | undefined;
  profile_pic?: TParam extends "profile_pic"
    ? string
    : never | undefined | undefined;
  quotes?: TParam extends "quotes" ? string : never | undefined | undefined;
  relationship_status?: TParam extends "relationship_status"
    ? string
    : never | undefined | undefined;
  religion?: TParam extends "religion" ? string : never | undefined | undefined;
  shared_login_upgrade_required_by?: TParam extends "shared_login_upgrade_required_by"
    ? unknown
    : never | undefined | undefined;
  short_name?: TParam extends "short_name"
    ? unknown
    : never | undefined | undefined;
  significant_other?: TParam extends "significant_other"
    ? facebook.User
    : never | undefined | undefined;
  sports?: TParam extends "sports"
    ? Array<facebook.Experience>
    : never | undefined | undefined;
  supports_donate_button_in_live_video?: TParam extends "supports_donate_button_in_live_video"
    ? boolean
    : never | undefined | undefined;
  token_for_business?: TParam extends "token_for_business"
    ? facebook.VideoUploadLimits
    : never | undefined | undefined;
  video_upload_limits?: TParam extends "video_upload_limits"
    ? string
    : never | undefined | undefined;
  website?: TParam extends "website" ? string : never | undefined | undefined;
}

interface Account {
  access_token: string;
  id: string;
  name: string;
  instagram_business_account?: {
    id: string;
  };
}

function createFbSdk({
  appId,
  cursorBasedPaginationLimit,
  logError,
}: {
  appId?: string;
  cursorBasedPaginationLimit?: number;
  logError?: (err: Error) => void;
} = {}) {
  /**
   * Set appId if provided
   */
  if (appId) {
    useFbInitParamsStore.setState({ appId });
  }

  const fbSdk = {
    ...(() => {
      const api = {
        async login({
          loginOptions,
          signal,
        }: {
          loginOptions?: facebook.LoginOptions;
          signal?: AbortSignal;
        }) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.login((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result =
                    StatusResponseSchema.passthrough().safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(response as typeof result.data);
                }, loginOptions);
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "LoginError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
        ...(() => {
          /**
           * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/451dc8f/types/facebook-js-sdk/index.d.ts#L81
           */
          async function api<TResponse>(path: string): Promise<TResponse>;
          async function api<TParams extends object, TResponse>(
            path: string,
            params: TParams,
          ): Promise<TResponse>;
          async function api<TParam extends facebook.UserField>(
            path: "/me",
            params: { fields: Array<TParam> },
          ): Promise<Me<TParam>>;
          async function api<TParams extends object, TResponse>(
            path: string,
            method: "get" | "post" | "delete",
            params: TParams,
          ): Promise<TResponse>;
          async function api(...args: Array<unknown>): Promise<unknown> {
            return new Promise((resolve, reject) => {
              try {
                (FB.api as (...args: Array<unknown>) => void)(
                  ...args,
                  (response: unknown) => {
                    resolve(response as unknown);
                  },
                );
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "ApiError";
                        err.cause = error;
                      })(),
                );
              }
            });
          }
          async function getMe({ signal }: { signal?: AbortSignal }) {
            signal?.throwIfAborted();
            const res = await api("/me", {
              fields: ["id", "name", "picture"],
            });
            signal?.throwIfAborted();
            const parsedResult = UserSchema.safeParse(res);
            if (!parsedResult.success) {
              logError?.(parsedResult.error);
              throw parsedResult.error;
            }
            return parsedResult.data;
          }
          async function getInstagramUser({
            signal,
            id,
          }: {
            signal?: AbortSignal;
            id: string;
          }) {
            signal?.throwIfAborted();
            const res = await api(`/${id}`, {
              fields: ["id", "name", "username", "profile_picture_url"],
            });
            signal?.throwIfAborted();
            const parsedResult = InstagramUserSchema.safeParse(res);
            if (!parsedResult.success) {
              logError?.(parsedResult.error);
              throw parsedResult.error;
            }
            return parsedResult.data;
          }
          /**
           * https://developers.facebook.com/docs/graph-api/reference/user/accounts/
           */
          async function getAccounts({ signal }: { signal?: AbortSignal }) {
            signal?.throwIfAborted();
            let next: string | undefined;
            async function getPage() {
              signal?.throwIfAborted();
              const res = next
                ? (
                    await axios(next, {
                      ...(!signal ? null : { signal }),
                    })
                  ).data
                : await api("/me/accounts", {
                    fields: [
                      "access_token",
                      "id",
                      "name",
                      "picture",
                      "instagram_business_account",
                    ],
                    ...(!cursorBasedPaginationLimit
                      ? null
                      : {
                          limit: cursorBasedPaginationLimit,
                        }),
                  });
              signal?.throwIfAborted();
              const parsedResult =
                CursorBasedPaginationSchema(AccountSchema).safeParse(res);
              if (!parsedResult.success) {
                logError?.(parsedResult.error);
                throw parsedResult.error;
              }
              return parsedResult.data;
            }
            const accounts: Array<z.infer<typeof AccountSchema>> = [];
            async function fetchPage() {
              const res = await getPage();
              signal?.throwIfAborted();
              res.data.forEach((account) => {
                if (accounts.some((target) => target.id === account.id)) return;
                accounts.push(account);
              });
              next = res.paging?.next ?? undefined;
            }
            await fetchPage();
            while (next) {
              await fetchPage();
            }
            return accounts;
          }
          /**
           * https://developers.facebook.com/docs/marketing-api/business-manager/get-started/
           */
          async function getBusinesses({ signal }: { signal?: AbortSignal }) {
            signal?.throwIfAborted();
            let next: string | undefined;
            async function getPage() {
              signal?.throwIfAborted();
              const res = next
                ? (
                    await axios(next, {
                      ...(!signal ? null : { signal }),
                    })
                  ).data
                : await api(`/me/businesses`, {
                    fields: ["id", "name"],
                    ...(!cursorBasedPaginationLimit
                      ? null
                      : {
                          limit: cursorBasedPaginationLimit,
                        }),
                  });
              signal?.throwIfAborted();
              const parsedResult =
                CursorBasedPaginationSchema(BusinessSchema).safeParse(res);
              if (!parsedResult.success) {
                logError?.(parsedResult.error);
                throw parsedResult.error;
              }
              return parsedResult.data;
            }
            const businesses: Array<z.infer<typeof BusinessSchema>> = [];
            async function fetchPage() {
              const res = await getPage();
              signal?.throwIfAborted();
              res.data.forEach((account) => {
                if (businesses.some((target) => target.id === account.id))
                  return;
                businesses.push(account);
              });
              next = res.paging?.next ?? undefined;
            }
            await fetchPage();
            while (next) {
              await fetchPage();
            }
            return businesses;
          }
          /**
           * https://developers.facebook.com/docs/marketing-api/reference/business/owned_whatsapp_business_accounts/
           */
          async function getOwnedWhatsappBusinessAccounts({
            businessId,
            signal,
          }: {
            businessId: z.infer<typeof BusinessSchema.shape.id>;
            signal?: AbortSignal;
          }) {
            signal?.throwIfAborted();
            let next: string | undefined;
            async function getPage() {
              signal?.throwIfAborted();
              const res = next
                ? (
                    await axios(next, {
                      ...(!signal ? null : { signal }),
                    })
                  ).data
                : await api(`/${businessId}/owned_whatsapp_business_accounts`, {
                    fields: [
                      "id",
                      "name",
                      "timezone_id",
                      "message_template_namespace",
                    ],
                    ...(!cursorBasedPaginationLimit
                      ? null
                      : {
                          limit: cursorBasedPaginationLimit,
                        }),
                  });
              signal?.throwIfAborted();
              const parsedResult = CursorBasedPaginationSchema(
                WhatsappBusinessAccountSchema,
              )
                .passthrough()
                .safeParse(res);
              if (!parsedResult.success) {
                logError?.(parsedResult.error);
                throw parsedResult.error;
              }
              return parsedResult.data;
            }
            const accounts: Array<
              z.infer<typeof WhatsappBusinessAccountSchema>
            > = [];
            async function fetchPage() {
              const res = await getPage();
              signal?.throwIfAborted();
              res.data.forEach((account) => {
                if (accounts.some((target) => target.id === account.id)) return;
                accounts.push(account);
              });
              next = res.paging?.next ?? undefined;
            }
            await fetchPage();
            while (next) {
              await fetchPage();
            }
            return accounts;
          }
          return {
            api,
            getMe,
            getInstagramUser,
            getAccounts,
            getBusinesses,
            getOwnedWhatsappBusinessAccounts,
          };
        })(),
        async getLoginStatus({
          signal,
        }: {
          signal?: AbortSignal;
        } = {}) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.getLoginStatus((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result =
                    StatusResponseSchema.passthrough().safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(response as typeof result.data);
                });
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "GetLoginStatusError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
        async logout({
          signal,
        }: {
          signal?: AbortSignal;
        } = {}) {
          return new Promise<z.infer<typeof StatusResponseSchema>>(
            (resolve, reject) => {
              if (signal?.aborted) {
                reject(new DOMException("Aborted", "AbortError"));
                return;
              }
              try {
                FB.logout((response) => {
                  if (signal?.aborted) {
                    reject(new DOMException("Aborted", "AbortError"));
                    return;
                  }
                  const result =
                    StatusResponseSchema.passthrough().safeParse(response);
                  if (!result.success) {
                    logError?.(result.error);
                    reject(result.error);
                    return;
                  }
                  resolve(response as typeof result.data);
                });
              } catch (error) {
                reject(
                  error instanceof Error
                    ? error
                    : (() => {
                        const err = new Error("Unknown error");
                        err.name = "LogoutError";
                        err.cause = error;
                      })(),
                );
              }
            },
          );
        },
      };

      const random = nanoid();

      const queryKeys = {
        all: [{ [`fb-${random}`]: random }] as const,
        loginStatus() {
          return [
            {
              ...queryKeys.all[0],
              fbLoginStatus: random,
            },
          ];
        },
        api({
          accessToken = "",
          url,
          params = {},
        }: {
          accessToken?: string | undefined;
          url: string;
          params?: object | undefined;
        }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              url,
              params,
              api: random,
            },
          ] as const;
        },
        me({ accessToken = "" }: { accessToken?: string | undefined }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              me: random,
            },
          ] as const;
        },
        instagramUser({
          accessToken = "",
          id,
        }: {
          accessToken?: string | undefined;
          id: string;
        }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              id,
              instagramUser: random,
            },
          ] as const;
        },
        accounts({ accessToken = "" }: { accessToken?: string | undefined }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              accounts: random,
            },
          ] as const;
        },
        businesses({ accessToken = "" }: { accessToken?: string | undefined }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              businesses: random,
            },
          ] as const;
        },
        whatsappBusinessAccounts({
          accessToken = "",
          businessId,
        }: {
          accessToken?: string | undefined;
          businessId: z.infer<typeof BusinessSchema.shape.id>;
        }) {
          return [
            {
              ...queryKeys.all[0],
              accessToken,
              businessId,
              whatsappBusinessAccounts: random,
            },
          ] as const;
        },
      };

      function useAuthResponse() {
        const loginStatusQuery = queries.useLoginStatusQuery();
        return (
          (loginStatusQuery.isSuccess && loginStatusQuery.data.authResponse) ||
          null
        );
      }

      function useAccessToken() {
        const authResponse = useAuthResponse();
        return authResponse && "accessToken" in authResponse
          ? authResponse.accessToken
          : "";
      }

      const queries = {
        useAuthResponse,
        useLoginStatusQuery() {
          return useQuery({
            queryKey: queryKeys.loginStatus(),
            async queryFn({ signal }) {
              const res = await api.getLoginStatus({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
          });
        },
        useApiQuery: (() => {
          const useApiQuery: {
            <TResponse>(path: string): UseQueryResult<TResponse | FbSdkErr>;
            <TParams extends object, TResponse>(
              path: string,
              params: TParams,
            ): UseQueryResult<TResponse | FbSdkErr>;
            <TParam extends facebook.UserField>(
              path: "/me",
              params: { fields: Array<TParam> },
            ): UseQueryResult<Me<TParam> | FbSdkErr>;
            <TParams extends object, TResponse>(
              path: string,
              method: "get" | "post" | "delete",
              params: TParams,
            ): UseQueryResult<TResponse | FbSdkErr>;
          } = function useApiQuery<TParams extends object>(
            url: string,
            params?: TParams,
          ) {
            const accessToken = useAccessToken();
            return useQuery({
              queryKey: [
                ...queryKeys.api({
                  accessToken,
                  url,
                  params,
                }),
              ],
              queryFn: async () => {
                const res = await (api.api as any)(url, params);
                return res;
              },
              enabled: Boolean(accessToken),
            });
          };
          return useApiQuery;
        })(),
        useMeQuery() {
          const accessToken = useAccessToken();
          return useQuery({
            queryKey: [
              ...queryKeys.me({
                accessToken,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getMe({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useUserQuery({ id }: { id: string }) {
          const accessToken = useAccessToken();
          return useQuery({
            queryKey: [
              ...queryKeys.instagramUser({
                accessToken,
                id,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getInstagramUser({
                ...(!signal ? null : { signal }),
                id,
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useAccountsQuery() {
          const accessToken = useAccessToken();
          return useQuery({
            queryKey: [
              ...queryKeys.accounts({
                accessToken,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getAccounts({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useBusinessesQuery() {
          const accessToken = useAccessToken();
          return useQuery({
            queryKey: [
              ...queryKeys.businesses({
                accessToken,
              }),
            ],
            async queryFn({ signal }) {
              const res = await api.getBusinesses({
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
        useWhatsappBusinessAccountsQuery({
          businessId,
        }: {
          businessId: z.infer<typeof BusinessSchema.shape.id>;
        }) {
          const accessToken = useAccessToken();
          return useQuery({
            queryKey: queryKeys.whatsappBusinessAccounts({
              businessId,
              accessToken,
            }),
            async queryFn({ signal, queryKey }) {
              const res = await api.getOwnedWhatsappBusinessAccounts({
                businessId: queryKey[0].businessId,
                ...(!signal ? null : { signal }),
              });
              return res;
            },
            enabled: Boolean(accessToken),
          });
        },
      };

      function useInvalidateQueries() {
        const queryClient = useQueryClient();
        const invalidateQueries = useEventCallback(async () => {
          await queryClient.invalidateQueries({
            queryKey: queryKeys.all,
          });
        });
        return invalidateQueries;
      }

      const mutations = {
        useLoginMutation(
          options?: Omit<
            MutationOptions<
              z.infer<typeof StatusResponseSchema>,
              Error,
              Parameters<typeof api.login>[0],
              unknown
            >,
            "mutationFn"
          >,
        ) {
          const invalidateQueries = useInvalidateQueries();
          return useMutation({
            ...options,
            async mutationFn(params) {
              FB.init(useFbInitParamsStore.getState());
              return await api.login(params);
            },
            async onSettled(...args) {
              invalidateQueries();
              return await options?.onSettled?.(...args);
            },
          });
        },
        useLogoutMutation(
          options?: Omit<
            MutationOptions<
              z.infer<typeof StatusResponseSchema>,
              Error,
              Parameters<typeof api.logout>[0],
              unknown
            >,
            "mutationFn"
          >,
        ) {
          const invalidateQueries = useInvalidateQueries();
          return useMutation({
            ...options,
            async mutationFn(params) {
              return api.logout(params);
            },
            async onSettled(...args) {
              invalidateQueries();
              return await options?.onSettled?.(...args);
            },
          });
        },
      };

      const hooks = {
        queries,
        mutations,
        useFbSdkLoaded,
        useFbInitParamsStore,
      };

      return {
        api,
        hooks,
      };
    })(),
  };

  return fbSdk;
}

export {
  AccountSchema,
  BusinessSchema,
  createFbSdk,
  WhatsappBusinessAccountSchema,
};
export type { Account };
