import { fc } from "@chatbotgang/etude/react/fc";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { INPUT_NUMBER_PREVENT_KEY } from "@/appConstant";
import { Button } from "@/components/Button";
import { InfoBox } from "@/components/InfoBox";
import { Modal } from "@/components/Modal";
import { useNumberFormat } from "@/components/NumberFormat/hooks/useNumberFormat";
import { Typography } from "@/components/Typography";
import { interlude } from "@/interlude";

export const ThresholdSettingModal = fc(function ThresholdSettingModal({
  open,
  closeModal,
}: {
  open: boolean;
  closeModal: () => void;
}) {
  const { t } = useTranslation();

  const balanceThresholdQuery = interlude.balance.useGetBalanceThreshold();
  const [balanceThreshold, setBalanceThreshold] = useState<number>();

  const computedBalanceThreshold = useMemo(
    () => balanceThreshold ?? balanceThresholdQuery.data?.threshold ?? 0,
    [balanceThresholdQuery.data?.threshold, balanceThreshold],
  );

  const { enqueueSnackbar } = useSnackbar();
  const balanceThresholdMutation = interlude.balance.useSetBalanceThreshold(
    {},
    {
      onSuccess: () => {
        closeModal();
        enqueueSnackbar(t("billing.alertSetting.update.success"), {
          variant: "success",
        });
      },
    },
  );

  const resetMutationError = useHandler(() => {
    if (balanceThresholdMutation.isError) {
      balanceThresholdMutation.reset();
    }
  });

  const handleCloseModal = useHandler(() => {
    setBalanceThreshold(undefined);
    closeModal();
    resetMutationError();
  });

  const { numberFormat } = useNumberFormat({ numberFormatPreset: "revenue" });

  return (
    <Modal
      open={open}
      title={t("billing.alertSetting.modal.title")}
      handleClose={handleCloseModal}
      footer={
        <Stack gap="12px" direction="row" justifyContent="flex-end">
          <Button key="cancel" variant="secondary" onClick={handleCloseModal}>
            {t("billing.alertSetting.modal.button.cancel")}
          </Button>
          <Button
            key="save"
            variant="primary"
            onClick={() => {
              balanceThresholdMutation.mutate({
                threshold: computedBalanceThreshold,
              });
            }}
            loading={balanceThresholdMutation.isPending}
            disabled={
              computedBalanceThreshold ===
                balanceThresholdQuery.data?.threshold ||
              computedBalanceThreshold <= 0
            }
          >
            {t("billing.alertSetting.modal.button.setting")}
          </Button>
        </Stack>
      }
    >
      <>
        <Typography variant="body" fontWeight={500} style={{ marginBottom: 5 }}>
          {t("billing.alertSetting.modal.content.para1", {
            value: numberFormat(balanceThresholdQuery.data?.threshold ?? 0),
          })}
        </Typography>
        <Typography
          variant="note"
          style={{ display: "block", marginBottom: 12 }}
        >
          {t("billing.alertSetting.modal.content.para2")}
        </Typography>
        <TextField
          type="number"
          value={computedBalanceThreshold}
          onFocus={resetMutationError}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (isNaN(value)) return;
            if (value > Number.MAX_SAFE_INTEGER) return;
            if (value < 0) return;

            setBalanceThreshold(value);
          }}
          onKeyDown={(e) => {
            if (INPUT_NUMBER_PREVENT_KEY.includes(e.key)) {
              e.preventDefault();
            }
          }}
          sx={{
            width: "100%",
          }}
        />
        {balanceThresholdMutation.isError && (
          <InfoBox
            variant="error"
            title={t("billing.alertSetting.update.failed")}
            sx={{ marginTop: "8px" }}
          />
        )}
      </>
    </Modal>
  );
});
