import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";
import { memo } from "react";

export const KeyIcon = memo(function KeyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5899 2.17737C11.343 2.28518 12.1012 2.67298 12.714 3.28573C13.3267 3.89848 13.7145 4.65664 13.8223 5.40982C13.9302 6.16355 13.758 6.9557 13.1854 7.52837C12.6127 8.10104 11.8205 8.27322 11.0668 8.16533C10.5109 8.08576 9.95225 7.85364 9.45137 7.49113L4.22868 12.7138C3.96833 12.9742 3.54622 12.9742 3.28587 12.7138C3.02552 12.4535 3.02552 12.0314 3.28587 11.771L2.81446 11.2996C2.55411 11.0393 2.55411 10.6171 2.81446 10.3568C3.07481 10.0965 3.49692 10.0965 3.75727 10.3568L4.22868 10.8282L5.17148 9.8854L4.70008 9.41399C4.43973 9.15364 4.43973 8.73153 4.70008 8.47118C4.96043 8.21083 5.38254 8.21083 5.64289 8.47118L6.11429 8.94259L8.50856 6.54832C8.14605 6.04744 7.91393 5.4888 7.83436 4.93288C7.72647 4.17915 7.89864 3.387 8.47132 2.81433C9.04399 2.24166 9.83614 2.06948 10.5899 2.17737ZM10.4009 3.49725C9.96085 3.43425 9.62398 3.54728 9.41412 3.75714C9.20426 3.967 9.09124 4.30386 9.15424 4.74396C9.21731 5.18462 9.45647 5.68511 9.88553 6.11416C10.3146 6.54322 10.8151 6.78238 11.2557 6.84545C11.6958 6.90845 12.0327 6.79543 12.2426 6.58556C12.4524 6.3757 12.5654 6.03884 12.5024 5.59874C12.4394 5.15809 12.2002 4.6576 11.7711 4.22854C11.3421 3.79949 10.8416 3.56033 10.4009 3.49725Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
});
