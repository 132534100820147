import { fc } from "@chatbotgang/etude/react/fc";
import { css } from "@emotion/react";
import { Alert, Stack } from "@mui/material";
import type { AlertColor, AlertProps } from "@mui/material/Alert";
import { theme } from "@polifonia/theme";

import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";

/** Available variants for the InfoBox component */
type InfoBoxVariant = "primary" | "plain" | "success" | "warning" | "error";

/** Mapping between InfoBox variants and Alert severities */
const variantToSeverityMap: Record<InfoBoxVariant, AlertColor> = {
  primary: "info",
  plain: "info", // Use info as base for plain variant, but override styles
  success: "success",
  warning: "warning",
  error: "error",
};

/** Get text color for each variant */
const getVariantColor = (variant: InfoBoxVariant): string => {
  const colors = {
    primary: theme.colors.staticFgPrimary,
    plain: theme.colors.staticFgPlain,
    success: theme.colors.staticFgPositive,
    warning: theme.colors.staticFgAttentive,
    error: theme.colors.staticFgNegative,
  };
  return colors[variant];
};

/** Get background color for each variant */
const getVariantBgColor = (variant: InfoBoxVariant): string => {
  const colors = {
    primary: theme.colors.staticBgPrimary,
    plain: theme.colors.staticBgPlain,
    success: theme.colors.staticBgPositive,
    warning: theme.colors.staticBgAttentive,
    error: theme.colors.staticBgNegative,
  };
  return colors[variant];
};

/** Get icon motif for each variant */
const getIconMotif = (variant: InfoBoxVariant): React.ReactNode => {
  const motifs = {
    primary: <MotifIcon un-i-motif="circle_info" />,
    plain: <MotifIcon un-i-motif="circle_info" />,
    success: <MotifIcon un-i-motif="circle_check" />,
    warning: <MotifIcon un-i-motif="warning" />,
    error: <MotifIcon un-i-motif="circle_cross" />,
  };
  return motifs[variant];
};

/**
 * Properties for the InfoBox component.
 */
interface InfoBoxProps {
  /**
   * Visual style variant of the InfoBox.
   * @defaultValue "primary"
   */
  variant?: InfoBoxVariant;
  /**
   * Main heading text displayed at the top of the InfoBox.
   */
  title: string;
  /**
   * Detailed message text displayed below the title.
   */
  description?: string;
  /**
   * Optional action button configuration.
   */
  action?: {
    /**
     * Text label for the action button.
     */
    label: string;
    /**
     * Callback function triggered when action is clicked.
     */
    onClick: () => void;
  };
  /**
   * Whether to remove the background and padding.
   * @defaultValue false
   */
  transparent?: boolean;
  /**
   * Additional CSS styles for the InfoBox.
   */
  sx?: AlertProps["sx"];
}

/**
 * InfoBox component displays important information or messages with different visual styles.
 *
 * @param props - The component props
 * @returns A React component that displays information in a styled box
 *
 * @example
 * ```tsx
 * <InfoBox
 *   variant="success"
 *   title="Operation Complete"
 *   description="Your changes have been saved successfully"
 *   action={{
 *     label: "Undo",
 *     onClick: () => handleUndo()
 *   }}
 * />
 * ```
 *
 * @remarks
 * The component supports five variants:
 * - primary (default): For general information
 * - plain: For neutral messages
 * - success: For positive outcomes
 * - warning: For cautionary messages
 * - error: For error states
 */
export const InfoBox = fc<InfoBoxProps>(function InfoBox({
  variant = "primary",
  title,
  description,
  action,
  transparent = false,
  sx,
}) {
  const severity = variantToSeverityMap[variant];
  const textColor = getVariantColor(variant);
  const bgColor = getVariantBgColor(variant);
  const icon = getIconMotif(variant);

  return (
    <Alert
      {...(sx && { sx })}
      severity={severity}
      variant="outlined"
      icon={icon}
      css={css`
        color: ${textColor};
        padding: ${transparent ? "0" : "12px"};
        background-color: ${transparent ? "transparent" : bgColor};
      `}
      action={
        action && (
          <Typography
            variant="body"
            fontWeight="medium"
            onClick={action.onClick}
            css={css`
              text-decoration: underline;
              cursor: pointer;
              color: ${textColor};
            `}
          >
            {action.label}
          </Typography>
        )
      }
    >
      <Stack spacing="4px">
        <Typography variant="body" color={textColor} fontWeight="medium">
          {title}
        </Typography>
        {description ? (
          <Typography variant="body" color={textColor} fontWeight="regular">
            {description}
          </Typography>
        ) : null}
      </Stack>
    </Alert>
  );
});
