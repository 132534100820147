import { css } from "@emotion/react";
import { TextField, type TextFieldProps } from "@mui/material";
import { useMemo } from "react";

import { CopyButton } from "@/components/Button/CopyButton";
import { defineStyles } from "@/internal/emotion";

const styles = defineStyles({
  CopyInput: css({
    ".MuiInputBase-root": {
      paddingRight: 0,
    },
  }),
  copyButton: css({
    minWidth: "auto",
    span: {
      marginRight: 0,
    },
  }),
});

const CopyInput: React.FC<TextFieldProps> = (inputProps) => {
  const text = useMemo(() => {
    if (inputProps.value === undefined) return "";
    if (typeof inputProps.value === "string") return inputProps.value;
    return JSON.stringify(inputProps.value);
  }, [inputProps.value]);
  return (
    <TextField
      css={styles.CopyInput}
      {...inputProps}
      value={text}
      slotProps={{
        input: {
          readOnly: true,
          endAdornment: (
            <CopyButton
              css={styles.copyButton}
              variant="transparent"
              text={text}
              size="small"
              children={null}
            />
          ),
        },
      }}
    />
  );
};

export { CopyInput };
