import { css } from "@emotion/react";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { theme } from "@polifonia/theme";
import { fakeT } from "@polifonia/utils/react/fakeT";
import type { InfiniteData } from "@tanstack/react-query";
import { getRouteApi, useNavigate } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { SearchInput } from "@/components/Input/SearchInput";
import { MotifIcon } from "@/components/MotifIcon";
import { TableRowsLoader } from "@/components/TableRowsLoader";
import { Tooltip } from "@/components/Tooltip";
import { Typography } from "@/components/Typography";
import { isMaacRole } from "@/features/accessManagement/roleMap";
import { useAccessManagementFeatureAvailable } from "@/features/accessManagement/useAccessManagementFeatureAvailable";
import { useUserPermission } from "@/features/accessManagement/useUserPermission";
import type { InterludeTypes } from "@/interlude";
import { interlude, type interludeClient } from "@/interlude";
import { EditModal } from "@/pages/userManagement/components/EditModal";
import EMPTY_BOX_PATH from "@/statics/emptyBox.svg";

const t = fakeT;

const routeApi = getRouteApi("/user-management");
const PAGE_SIZE = 100;

const maacRoleTranslationKeyMap = {
  "maac:admin": t("userManagement.table.maacRoles.admin"),
  "maac:marketer": t("userManagement.table.maacRoles.marketer"),
} satisfies Record<InterludeTypes["MaacRoleName"], string>;

const useUsers = (searchRequestValue: string | undefined) => {
  const usersQuery = interlude.user.useInfiniteQuery(
    "/api/v1/users/list",
    {
      queries: {
        page: 1,
        pageSize: PAGE_SIZE,
        searchFilter: searchRequestValue,
      },
    },
    {
      initialPageParam: 1,
      getPageParamList: () => ["page"],
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length <= 0) return undefined;
        return {
          queries: {
            page: allPages.length + 1,
          },
        };
      },
      select: useCallback(
        (
          data: InfiniteData<
            Awaited<ReturnType<typeof interludeClient.user.users>>
          >,
        ) => data,
        [],
      ),
    },
  );

  useEffect(
    function recursivelyFetchUsers() {
      const lastPage =
        usersQuery.data?.pages[usersQuery.data.pages.length - 1] ?? [];
      if (lastPage.length <= 0) return;
      if (!usersQuery.hasNextPage) return;
      if (usersQuery.isFetchingNextPage) return;
      if (usersQuery.isFetching) return;
      if (usersQuery.isPending) return;
      usersQuery.fetchNextPage();
    },
    [usersQuery],
  );

  return usersQuery;
};

interface MaacUserProps {
  setMaacUserCount: (count: number) => void;
}

export const MaacUsers: FC<MaacUserProps> = ({ setMaacUserCount }) => {
  const { t } = useTranslation();

  const {
    hasPermission,
    permissionLoading,
    email: meEmail,
  } = useUserPermission();

  const accessManagementFeatureAvailable =
    useAccessManagementFeatureAvailable();

  const canUpdateUserRoles = useMemo(
    () =>
      hasPermission("maac:user_roles:assign") &&
      hasPermission("maac:user_roles:unassign"),
    [hasPermission],
  );

  const { "target-edit-user-email": targetEditUserEmail } =
    routeApi.useSearch();

  const [searchInputValue, setSearchInputValue] = useState(
    targetEditUserEmail ?? "",
  );

  const [searchRequestValue, setSearchRequestValue] = useState<
    string | undefined
  >(targetEditUserEmail);

  const usersQuery = useUsers(searchRequestValue);

  const users = useMemo(
    () => usersQuery.data?.pages.flatMap((page) => page),
    [usersQuery.data],
  );

  const availableRoles = interlude.user.useAvailableRoles();

  const roleOptions = useMemo(() => {
    return (
      availableRoles.data
        ?.map((role) => {
          if (isMaacRole(role.name)) {
            return {
              label: t(
                maacRoleTranslationKeyMap[
                  role.name as keyof typeof maacRoleTranslationKeyMap
                ],
              ),
              value: role.id,
            };
          }

          return null;
        })
        .filter((role) => role !== null) ?? []
    );
  }, [availableRoles.data, t]);

  useEffect(
    function syncUserCount() {
      setMaacUserCount(users?.length ?? 0);
    },
    [users, setMaacUserCount],
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [targetEditUser, setTargetEditUser] = useState<
    InterludeTypes["User"] | null
  >(null);

  useEffect(
    function findTargetEditUserFromUrl() {
      if (permissionLoading || usersQuery.isPending) return;
      if (!targetEditUserEmail) return;
      if (!users) return;
      const targetEditUser = users.find(
        (user) => user.email === targetEditUserEmail,
      );
      if (!targetEditUser) {
        enqueueSnackbar(t("userManagement.editUser.userNotFound"), {
          variant: "warning",
        });
      }
      if (targetEditUser && canUpdateUserRoles) {
        setTargetEditUser(targetEditUser);
      }
      const { ["target-edit-user-email"]: _, ...rest } = queryString.parse(
        window.location.search,
      );
      navigate({
        to: window.location.pathname,
        search: rest,
        replace: true,
      });
    },
    [
      targetEditUserEmail,
      users,
      navigate,
      canUpdateUserRoles,
      enqueueSnackbar,
      t,
      permissionLoading,
      usersQuery.isPending,
    ],
  );

  return (
    <Stack direction="column" spacing={1.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1.5} alignItems="center">
          <SearchInput
            placeholder={t("userManagement.search.placeholder")}
            value={searchInputValue}
            onChange={(value) => {
              setSearchInputValue(value);
              if (!value) {
                setSearchRequestValue(undefined);
              }
            }}
            onSearch={(value) => {
              if (value) {
                setSearchRequestValue(value);
              } else {
                setSearchRequestValue(undefined);
              }
            }}
          />
          {searchRequestValue && !usersQuery.isPending ? (
            <Typography variant="body">
              {t("userManagement.search.resultWithCount", {
                count: users?.length ?? 0,
              })}
            </Typography>
          ) : null}
        </Stack>
        <Button variant="secondary" href={t("userManagement.table.hcLink")}>
          {t("userManagement.table.hcLabel")}
        </Button>
      </Stack>
      <TableContainer
        sx={{
          height: "calc(100vh - 200px)",
          overflowX: "auto",
        }}
      >
        <Table aria-label="channel table" stickyHeader sx={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("userManagement.table.email")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="body"
                  fontWeight={500}
                  color={theme.colors.staticFgNote}
                >
                  {t("userManagement.table.maacRoles")}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {usersQuery.isPending ? <TableRowsLoader rowsNum={5} /> : null}
            {searchRequestValue && users?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={3}
                  align="center"
                  sx={{ padding: "110px 0", border: "none" }}
                >
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                    spacing={3}
                  >
                    <img src={EMPTY_BOX_PATH} alt="No result found" />
                    <Typography
                      variant="body"
                      color={theme.colors.staticFgNote}
                    >
                      {t("userManagement.search.notFoundNotice")}
                    </Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            ) : null}
            {!usersQuery.isPending
              ? users?.map((user) => (
                  <TableRow key={user.email}>
                    <TableCell component="th" scope="row">
                      <Typography variant="body">{user.email}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="body">
                        {user.roles
                          .map((role) => {
                            if (isMaacRole(role.name))
                              return t(
                                maacRoleTranslationKeyMap[
                                  role.name as keyof typeof maacRoleTranslationKeyMap
                                ] || "",
                              );
                            return "";
                          })
                          .filter((role) => role !== "")
                          .join(", ")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={
                          !accessManagementFeatureAvailable ? (
                            <>
                              <div>
                                {t("userManagement.upgradePlan.tooltip")}
                              </div>
                              <Button
                                sx={{ padding: "4px 0 0" }}
                                href={t(
                                  "userManagement.upgradePlan.tooltip.externalLink",
                                )}
                                variant="transparent"
                                endIcon={<MotifIcon un-i-motif="new_window" />}
                              >
                                {t("userManagement.upgradePlan.tooltip.action")}
                              </Button>
                            </>
                          ) : meEmail === user.email ? (
                            t("userManagement.table.canNotEditSelf")
                          ) : !canUpdateUserRoles ? (
                            t("userManagement.edit.disabled")
                          ) : (
                            ""
                          )
                        }
                        arrow={true}
                        placement="top"
                      >
                        <span>
                          <Button
                            variant="plain"
                            css={css({
                              padding: 6,
                              minWidth: "auto",
                            })}
                            onClick={() =>
                              setTargetEditUser({
                                email: user.email,
                                username: user.username,
                                roles: user.roles,
                              })
                            }
                            disabled={
                              !accessManagementFeatureAvailable ||
                              meEmail === user.email ||
                              !canUpdateUserRoles
                            }
                          >
                            <MotifIcon
                              un-i-motif="edit"
                              css={css({
                                fontSize: 16,
                                color: theme.colors.buttonPlainDefault,
                              })}
                            />
                          </Button>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <EditModal
        open={!!targetEditUser}
        onClose={() => setTargetEditUser(null)}
        orgSource="maac"
        roleOptions={roleOptions}
        targetUser={targetEditUser}
      />
    </Stack>
  );
};
