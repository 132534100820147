import type { ReactNode } from "react";
import { memo } from "react";
import { createContext, useContext, useState } from "react";
import type { UseBoundStoreWithEqualityFn } from "zustand/traditional";
import { createWithEqualityFn } from "zustand/traditional";
import type {
  Mutate,
  StateCreator,
  StoreApi,
  StoreMutatorIdentifier,
} from "zustand/vanilla";

function createZustandContextStore<T>() {
  return function createZustandContextStore<
    Mos extends Array<[StoreMutatorIdentifier, unknown]> = [],
  >(
    initializer: StateCreator<T, [], Mos>,
    defaultEqualityFn: <U>(a: U, b: U) => boolean,
  ) {
    const Context = createContext<
      UseBoundStoreWithEqualityFn<Mutate<StoreApi<T>, Mos>> | undefined
    >(undefined);
    const Provider = memo(function Provider({
      children,
    }: {
      children: ReactNode;
    }) {
      const [useStore] = useState(() =>
        createWithEqualityFn<T, Mos>(initializer, defaultEqualityFn),
      );
      return <Context.Provider value={useStore}>{children}</Context.Provider>;
    });
    function useUseStore() {
      const useStore = useContext(Context);
      if (!useStore) throw new Error("useStore must be used within a Provider");
      return useStore;
    }

    function useStore(): T;
    function useStore<U>(
      selector: (state: T) => U,
      equalityFn?: (left: U, right: U) => boolean,
    ): U;
    function useStore<U>(...args: []): U {
      /**
       * Avoid naming this function as `useStore` because it would conflict with
       * the function name returned by `createZustandContextStore`. This
       * conflict can lead to `react-refresh` calling `computedFullKey`
       * infinitely, resulting in a `Maximum call stack size exceeded` error.
       *
       * @see https://github.com/chatbotgang/Zeffiroso/pull/1047
       */
      const useRealStore = useUseStore();
      return useRealStore(...args) as U;
    }
    return {
      useStore,
      useUseStore,
      Provider,
    };
  };
}

export { createZustandContextStore };
