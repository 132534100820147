import CssBaseline from "@mui/material/CssBaseline";
import { MuiThemeProvider } from "@polifonia/theme/Provider";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { memo } from "react";

import { ToastProvider } from "@/components/Toast";
import { useFeatureFlag } from "@/features/featureFlag";
import { StatsigProvider } from "@/features/statsig";
import { PageErrorBoundary } from "@/layout/ErrorBoundary/PageErrorBoundary";
import { routeTree } from "@/pages/routes";
import { queryClient } from "@/queryClient";

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const router = createRouter({ routeTree });

const ReactQueryProvider = memo(function ReactQueryProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const position = useFeatureFlag("react-query-devtool");

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!position ? null : (
        <ReactQueryDevtools initialIsOpen={false} position={position} />
      )}
    </QueryClientProvider>
  );
});

export function App() {
  return (
    <MuiThemeProvider>
      <PageErrorBoundary reloadWindow>
        <ToastProvider>
          <StatsigProvider>
            <CssBaseline />
            <ReactQueryProvider>
              <RouterProvider router={router} />
            </ReactQueryProvider>
          </StatsigProvider>
        </ToastProvider>
      </PageErrorBoundary>
    </MuiThemeProvider>
  );
}
