import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { ErrorSchema } from "@polifonia/interlude/models";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { AxiosError, CanceledError, HttpStatusCode } from "axios";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { ConditionalKeys, Tagged } from "type-fest";

import { ResourceNotFoundError } from "@/features/error";
import { mutationErrorEventEmitter } from "@/features/error/mutationErrorEventEmitter";
import { logError } from "@/features/logger";
import type { InterludeTypes } from "@/interlude";
import { UnAuthError } from "@/interlude/interludeAxios";

const t = fakeT;

type NetworkErrorSymbol = Tagged<symbol, "NetworkError">;

const networkError = {
  message: "",
  code: Symbol("NetworkError") as NetworkErrorSymbol,
};

type NoTokenErrorSymbol = Tagged<symbol, "NoTokenError">;

const noTokenError = {
  message: "",
  code: Symbol("NoTokenError") as NoTokenErrorSymbol,
};

type ResourceNotFoundErrorSymbol = Tagged<symbol, "ResourceNotFoundError">;

const resourceNotFoundError = {
  message: "",
  code: Symbol("ResourceNotFoundError") as ResourceNotFoundErrorSymbol,
};

type ApiErrorResponseData =
  | InterludeTypes["Error"]
  | typeof networkError
  | typeof noTokenError
  | typeof resourceNotFoundError;

type ApiErrorCode = ApiErrorResponseData["code"];

const apiErrorCodeTranslationKeyMap = {
  [networkError.code]: t("common.apiError.networkError"),
  [noTokenError.code]: t("errorPage.tryFromTheOriginalApp"),
  [resourceNotFoundError.code]: t("common.apiError.resourceNotFound"),
  UNAUTHORIZED: t("errorPage.tryFromTheOriginalApp"),
  CHANNEL_WCCS_INFORMATION_URL_ALREADY_EXISTS: null,
  ORGANIZATION_NOT_FOUND: null,
  BALANCE_INSUFFICIENT: null,
  SUBSCRIPTION_NOT_FOUND: null,
  MONTHLY_USAGE_REPORT_NOT_FOUND: null,
  UNIFY_SCOPE_SETTING_NOT_FOUND: null,
  INTEGRATE_CDH_FAILED: null,
  UNIFY_KEY_UPDATE_FAIL: null,
  DISCONNECT_CDH_FAILED: null,
  GET_MERGE_STATE_FAILED: null,
  SERVE_CONTACT_UPDATE_EVENT_FAILED: null,
  GET_ORGANIZATIONS_FAILED: null,
  CHANNEL_NOT_FOUND: t("common.apiError.resourceNotFound"),
  CHANNEL_WCCS_INFORMATION_NOT_FOUND: null,
  ORGANIZATION_SETTING_NOT_FOUND: null,
  UNKNOWN_ERROR: t("common.applicationError.unknownError"),
} satisfies Record<ApiErrorCode, ReturnType<typeof t> | null>;

type NonGlobalApiError = ConditionalKeys<
  typeof apiErrorCodeTranslationKeyMap,
  null
>;

const nonGlobalApiErrors: Array<NonGlobalApiError> = Object.entries(
  apiErrorCodeTranslationKeyMap,
).flatMap(([key, value]) =>
  value === null ? [key] : [],
) as Array<NonGlobalApiError>;

function isNonGlobalApiError(code: ApiErrorCode): code is NonGlobalApiError {
  return nonGlobalApiErrors.includes(code as NonGlobalApiError);
}

/**
 * Get the error body from an unknown error. Return null if the error is not
 * valid.
 */
function getApiErrorBodyFromUnknownError(
  input: unknown,
): ApiErrorResponseData | null {
  /**
   * Check if the error is a specific type of error
   */
  if (input instanceof UnAuthError) {
    return noTokenError;
  }
  if (input instanceof ResourceNotFoundError) {
    return resourceNotFoundError;
  }

  if (input instanceof AxiosError) {
    if (input.code === "ERR_NETWORK") return networkError;

    if (input.response?.status === HttpStatusCode.NotFound)
      return resourceNotFoundError;

    const parseErrorResult = ErrorSchema.safeParse(input.response?.data);
    if (parseErrorResult.success) return parseErrorResult.data;
  }
  return null;
}

/**
 * Handle non-global errors. Return `true` if the error is handled.
 */
async function handleNonGlobalApiError(
  err: unknown,
  handler: {
    [key in NonGlobalApiError]?: (
      error: InterludeTypes["Error"] & {
        name: key;
      },
    ) => void | Promise<void>;
  },
): Promise<boolean> {
  const errorApiBody = getApiErrorBodyFromUnknownError(err);
  if (!errorApiBody) return false;
  if (!isNonGlobalApiError(errorApiBody.code)) return false;
  const callback = handler[errorApiBody.code];
  if (!callback) return false;
  // @ts-expect-error -- TS2590: Expression produces a union type that is too complex to represent.
  await callback(errorApiBody);
  return true;
}

export const getGlobalErrorTranslationKey = (error: unknown) => {
  const parsedError = getApiErrorBodyFromUnknownError(error);
  if (parsedError) {
    return apiErrorCodeTranslationKeyMap[parsedError.code];
  }
  logError(error);
};

/**
 * Hook to get error message from any error including cancellation error and
 * non-global error.
 */
function handleError<T = Error>(
  /**
   * The error to handle.
   */
  error: T,
): ReturnType<typeof t> | null {
  /**
   * Cancel error is not an error, it's just a signal to cancel the request.
   */
  if (error instanceof CanceledError) return null;
  const errorApiBody = getApiErrorBodyFromUnknownError(error);
  if (errorApiBody) {
    mutationErrorEventEmitter.emit("knownError", errorApiBody);
    // fixme: ?? null
    return apiErrorCodeTranslationKeyMap[errorApiBody.code] ?? null;
  }
  logError(error);
  return t("common.applicationError.unknownError");
}

type UseGetErrorMessageOptions = {
  unknownErrorMessage?: string;
};

/**
 * Hook to get error message from any error including cancellation error and
 * non-global error.
 */
function useGetErrorMessage() {
  const { t } = useTranslation();
  const getErrorMessage = useCallback<
    (e: unknown, options?: UseGetErrorMessageOptions) => string
  >(
    function getErrorMessage(e, options) {
      const errorApiBody = getApiErrorBodyFromUnknownError(e);
      const translationKey =
        (errorApiBody && errorApiBody?.code !== "UNKNOWN_ERROR"
          ? apiErrorCodeTranslationKeyMap[errorApiBody.code]
          : apiErrorCodeTranslationKeyMap.UNKNOWN_ERROR) ??
        apiErrorCodeTranslationKeyMap.UNKNOWN_ERROR;

      return (
        (translationKey === apiErrorCodeTranslationKeyMap.UNKNOWN_ERROR
          ? options?.unknownErrorMessage ||
            t(apiErrorCodeTranslationKeyMap.UNKNOWN_ERROR)
          : t(translationKey)) +
        (translationKey !== apiErrorCodeTranslationKeyMap.UNKNOWN_ERROR
          ? ""
          : `: ${
              e instanceof Error
                ? `[${e.name}]: ${e.message}`
                : inspectMessage`Non-error object thrown: ${e}`
            }`)
      );
    },
    [t],
  );
  /**
   * Get error message from any error including cancellation error and
   * non-global error.
   */
  return getErrorMessage;
}

export {
  apiErrorCodeTranslationKeyMap as apiErrorNameTransUtilsMap,
  getApiErrorBodyFromUnknownError,
  handleError,
  handleNonGlobalApiError,
  isNonGlobalApiError,
  useGetErrorMessage,
};
export type { ApiErrorCode, ApiErrorResponseData, NonGlobalApiError };
