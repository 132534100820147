import type { AnyFunction } from "@chatbotgang/etude/function/AnyFunction";
import type { ErrorBoundaryProps } from "@sentry/react";
import { ErrorBoundary } from "@sentry/react";
import type { QueryErrorResetBoundaryProps } from "@tanstack/react-query";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { useCallback } from "react";

interface PolifoniaErrorBoundaryProps extends ErrorBoundaryProps {}

/**
 * Integrated:
 *
 * - Sentry
 * - React Query
 */
const PolifoniaErrorBoundary: React.FC<ErrorBoundaryProps> = (props) => {
  const beforeCapture = useCallback<
    NonNullable<ErrorBoundaryProps["beforeCapture"]>
  >(
    function beforeCapture(...args) {
      /**
       * TODO: Add custom tags to Sentry scope.
       * example:
       * ```ts
       * const scope = args[0];
       * scope.setTag("lastUserEmail", useLastUserEmailStore.getState().value);
       * ```
       */
      return props.beforeCapture?.(...args);
    },
    [props],
  );
  const children = useCallback<
    Extract<QueryErrorResetBoundaryProps["children"], AnyFunction>
  >(
    function children(query) {
      return (
        <ErrorBoundary
          {...props}
          beforeCapture={beforeCapture}
          onReset={(...args) => {
            query.reset();
            props.onReset?.(...args);
          }}
        />
      );
    },
    [beforeCapture, props],
  );
  return <QueryErrorResetBoundary>{children}</QueryErrorResetBoundary>;
};

export { PolifoniaErrorBoundary as ErrorBoundary };
export type { PolifoniaErrorBoundaryProps as ErrorBoundaryProps };
