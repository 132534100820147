import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";
import { memo } from "react";

export const SyncIcon = memo<SvgIconProps>(function SyncIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.12902 4.78668C4.09761 3.50724 5.58996 2.66669 7.26282 2.66669C9.86736 2.66669 12.0072 4.53409 12.2965 7.00002H14.6667C14.3641 3.23336 11.133 0.333354 7.26282 0.333354C5.00368 0.333354 2.98213 1.45002 1.69252 3.17668L0.280464 1.80002V5.66669H4.29525L3.12902 4.78668ZM12.871 11.5467C11.8958 12.7934 10.4178 13.6034 8.76552 13.6034C6.24103 13.6034 4.13599 11.8303 3.76253 9.47461H1.33337C1.72007 13.1623 4.93558 16.0001 8.76552 16.0001C10.9737 16.0001 12.9573 14.9328 14.2326 13.2593L15.7195 14.6667V10.7H11.7047L12.871 11.5467Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
});
