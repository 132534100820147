import { createQueriesContext } from "@polifonia/utils/react-query/createQueriesContext";

import { interlude } from "@/interlude";

export function useRootQueries() {
  const meQuery = interlude.auth.useSuspenseGetMe();

  return {
    me: meQuery,
  };
}

export const RootQueriesContext =
  createQueriesContext<ReturnType<typeof useRootQueries>>();
