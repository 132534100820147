import type { DistributiveOmit, Overwrite } from "@mui/types";

const defaultComponent = "div" as const;

type UnIMotifProp = NonNullable<
  React.ComponentProps<typeof defaultComponent>["un-i-motif"]
>;

namespace MotifIcon {
  export type BaseComponentProps = {
    "un-i-motif": UnIMotifProp;
  };
  export type DefaultComponent = typeof defaultComponent;
  export interface OwnProps {
    component?: React.ElementType<BaseComponentProps>;
  }
  export type Props<
    TComponent extends React.ElementType<BaseComponentProps> = DefaultComponent,
  > = Overwrite<React.ComponentProps<TComponent>, OwnProps>;
  export interface Type {
    <const TComponent extends React.ElementType<BaseComponentProps>>(
      props: Overwrite<
        Props<TComponent>,
        {
          component: TComponent;
        }
      >,
    ): React.ReactNode;
    (props: DistributiveOmit<Props, "component">): React.ReactNode;
  }
}

const MotifIconInternal: MotifIcon.Type = ({
  component: Component = defaultComponent,
  ...props
}) => {
  return <Component {...props} />;
};

/**
 *
 * @example
 * ```tsx
 * <MotifIcon un-i-motif="paper_plane" /> // by default it renders a div
 * <MotifIcon un-i-motif="paper_plane" component="span" /> // renders a span
 * ```
 */
const MotifIcon = Object.assign(MotifIconInternal, { defaultComponent });

export { MotifIcon };
