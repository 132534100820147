import { UnificationKeysSchema } from "@polifonia/interlude/models";
import { constant } from "es-toolkit/compat";
import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { MultipleSelect, SelectValue } from "@/components/Select";
import { Select } from "@/components/Select/index";
import { unifyKeyLabelTranslationMap } from "@/features/unify-scope-setting/unifyKeyLabelTranslationMap";
import type { InterludeTypes } from "@/interlude";

export type UnifyKeySelectValue = SelectValue & {
  value: InterludeTypes["UnificationKeys"];
};

type UnifyKeySelectorProps = Omit<
  MultipleSelect.Props<InterludeTypes["UnificationKeys"], UnifyKeySelectValue>,
  "options"
> & {
  filter?: (key: InterludeTypes["UnificationKeys"]) => boolean;
};

export const UnifyKeySelector: FC<Omit<UnifyKeySelectorProps, "multiple">> = ({
  filter = constant(true),
  maxCount = 3,
  ...props
}) => {
  const { t } = useTranslation();
  const options = useMemo<
    MultipleSelect.Props<
      InterludeTypes["UnificationKeys"],
      UnifyKeySelectValue
    >["options"]
  >(
    function getOptions() {
      return UnificationKeysSchema.options.filter(filter).map((key) => ({
        value: key,
        label: t(unifyKeyLabelTranslationMap[key]),
      }));
    },
    [filter, t],
  );

  return (
    <Select<InterludeTypes["UnificationKeys"], UnifyKeySelectValue>
      maxCount={maxCount}
      {...props}
      options={options}
      multiple={true}
    />
  );
};
