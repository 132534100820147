import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { random } from "@chatbotgang/etude/string/random";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { SxProps } from "@mui/material";
import {
  Alert,
  Box,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  useEventCallback,
} from "@mui/material";
import {
  CL_WEB_SDK_EMBED_CODE_URL,
  CL_WEB_SDK_EMBED_SHOPIFY_CODE_URL,
} from "@polifonia/env";
import { theme } from "@polifonia/theme";
import { createContext } from "@polifonia/utils/react/createContext";
import useSwitch from "@react-hook/switch";
import { sortBy } from "es-toolkit";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { Badge } from "@/components/Badge";
import { Button } from "@/components/Button";
import { CopyButton } from "@/components/Button/CopyButton";
import { ExternalLink } from "@/components/ExternalLink";
import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";
import { isValidDomain } from "@/pages/channels/pages/detail/wccs/utils/isValidDomain";

const BadgeList = styled.ol({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  flexDirection: "column",
  gap: 24,
});

const BadgeItem: React.FC<{
  index: number;
  title: React.ReactNode;
  content: React.ReactNode;
}> = ({ index, title, content }) => {
  return (
    <Stack direction="row" gap="8px">
      <Badge variant="plain">{index}</Badge>
      <Stack direction="column" gap="8px" maxWidth="696px" width="100%">
        <Typography
          variant="h3"
          color={theme.colors.staticFgTitle}
          fontWeight={500}
        >
          {title}
        </Typography>
        {content}
      </Stack>
    </Stack>
  );
};

const DomainsContext = createContext<{
  domains: InterludeTypes["WccsChannel"]["channelInformation"]["domains"];
  toggleDisplayCreateForm: ReturnType<typeof useSwitch>[1];
}>();

const DeleteDomainButton: React.FC<{
  domainId: InterludeTypes["WccsChannel"]["channelInformation"]["domains"][number]["id"];
}> = ({ domainId }) => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const deleteMutation = interlude.channel.useDeleteChannelDomain(
    {
      params: {
        channelId: channel.id,
        domainId,
      },
    },
    {
      onSuccess() {
        enqueueSnackbar(t("common.updatedSuccessfully"), {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );
  const isCreateMode = useMemo(() => {
    return Number.isNaN(domainId);
  }, [domainId]);

  const handleDeleteDomain = useHandler(function handleDeleteDomain() {
    if (deleteMutation.isPending) return;
    deleteMutation.mutate(undefined);
  });

  const { toggleDisplayCreateForm } = DomainsContext.useContext();

  return (
    <IconButton
      size="small"
      onClick={isCreateMode ? toggleDisplayCreateForm.off : handleDeleteDomain}
    >
      <MotifIcon un-i-motif="bin" />
    </IconButton>
  );
};

interface FormFieldValues {
  id: number;
  url: string;
}

const defaultValues: FormFieldValues = { id: Number.NaN, url: "" };
const MAX_DOMAIN_LENGTH = 2048;

const DomainForm: React.FC<{
  domain?: { id: number; url: string };
}> = ({ domain = defaultValues }) => {
  const { toggleDisplayCreateForm, domains } = DomainsContext.useContext();
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm<FormFieldValues>({
    mode: "onChange",
    defaultValues: domain,
  });
  const createMutation = interlude.channel.useAddChannelDomain(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        toggleDisplayCreateForm.off();
        enqueueSnackbar(
          <Trans i18nKey="page.channel.install.domain.add.success" />,
          {
            variant: "success",
          },
        );
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );

  const editMutation = interlude.channel.useUpdateChannelDomain(
    {
      params: {
        channelId: channel.id,
        domainId: domain.id,
      },
    },
    {
      onSuccess() {
        enqueueSnackbar(t("page.channel.install.domain.update.success"), {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );

  const isCreate = useMemo(() => {
    return Number.isNaN(domain.id);
  }, [domain.id]);

  const onSubmitHandler = useHandler<SubmitHandler<FormFieldValues>>(
    function onSubmitHandler({ url }) {
      // prevent submit when loading
      if (createMutation.isPending || editMutation.isPending) return;
      if (isCreate) {
        createMutation.mutate({ url });
        return;
      }
      // prevent submit when url is the same
      if (url === domain.url) {
        return;
      }
      editMutation.mutate({ url });
    },
  );

  const handleBlur = useHandler<
    React.ComponentProps<typeof OutlinedInput>["onBlur"]
  >(function handleBlur() {
    handleSubmit(onSubmitHandler)();
  });

  const deletable = useMemo(() => {
    return domains.length > (isCreate ? 0 : 1);
  }, [isCreate, domains.length]);

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack direction="row" gap="8px" maxWidth="512px" alignItems="center">
        <Controller
          name="url"
          control={control}
          rules={{
            validate: (value) => {
              if (value.trim() === "") {
                return t("validation.url.required");
              }
              if (!isValidDomain(value)) {
                return t("validation.url.invalidFormat");
              }
              if (
                value !== domain.url &&
                domains.some((d) => d.url === value)
              ) {
                return t("page.channel.wccs.install.error.domainConflict");
              }
              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Stack direction="column" gap="4px" flex={1}>
              <Stack direction="row" gap="8px" alignItems="center">
                <OutlinedInput
                  {...field}
                  sx={{ flex: 1 }}
                  autoFocus={isCreate}
                  placeholder={t(
                    "page.channel.install.domain.input.placeholder",
                  )}
                  error={!!error}
                  onBlur={handleBlur}
                  disabled={createMutation.isPending}
                  slotProps={{
                    input: {
                      maxLength: MAX_DOMAIN_LENGTH,
                    },
                  }}
                  endAdornment={
                    isCreate ? null : (
                      <MotifIcon
                        un-i-motif="circle_check_fill"
                        css={css({
                          fontSize: "16px",
                          color: theme.colors.green060,
                        })}
                      />
                    )
                  }
                />
                {!deletable ? null : (
                  <DeleteDomainButton domainId={domain.id} />
                )}
              </Stack>
              {!error ? null : (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </Stack>
          )}
        />
      </Stack>
    </form>
  );
};

const MAX_DOMAINS_COUNT = 5;
const Domains: React.FC = () => {
  const { channel } = ChannelQueriesContext.useData();

  const domains = useMemo(() => {
    if (channel.type !== "wccs") return [];
    return sortBy(channel.channelInformation.domains, ["id"]);
  }, [channel.type, channel.channelInformation]);

  const [displayCreateForm, toggleDisplayCreateForm] = useSwitch(
    // default to create mode if there are no domains
    domains.length === 0,
  );

  const handleAddDomain = useHandler<
    React.ComponentProps<typeof Button>["onClick"]
  >(function handleAddDomain() {
    toggleDisplayCreateForm.on();
  });

  const domainsContextValue = useMemo(
    function cacheDomainsContextValue() {
      return {
        domains,
        toggleDisplayCreateForm,
      };
    },
    [domains, toggleDisplayCreateForm],
  );

  const reachMaxCount = useMemo(
    function checkReachMaxDomains() {
      return domains.length + (displayCreateForm ? 1 : 0) >= MAX_DOMAINS_COUNT;
    },
    [domains.length, displayCreateForm],
  );

  return (
    <DomainsContext.Provider value={domainsContextValue}>
      <Stack direction="column" gap="8px">
        {domains.map((domain) => (
          <DomainForm key={domain.id} domain={domain} />
        ))}
        {!displayCreateForm ? null : <DomainForm />}
        <Button
          disabled={reachMaxCount}
          variant="transparent"
          onClick={handleAddDomain}
          startIcon="+"
        >
          <Trans i18nKey="page.channel.install.domain.add.label" />
        </Button>
      </Stack>
    </DomainsContext.Provider>
  );
};

const sxPropsStyles = {
  embedCode: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.colors.neutral020,
    borderRadius: theme.shape.borderRadius,
    flex: 1,
    height: "336px",
  },
} satisfies SxProps<typeof theme>;

const AvailablePluginReSchema = z.enum([
  "dataLayer",
  "cyberbiz",
  "shopify",
  "shopline",
  "nineOneApp",
]);

type AvailablePluginRe = z.infer<typeof AvailablePluginReSchema>;

const CodeIframe: React.FC<{
  src: string;
}> = ({ src }) => {
  const [initSrc] = useState(src);
  const [iframeElement, setIframeElement] = useState<null | HTMLIFrameElement>(
    null,
  );
  const [iframePushStateReady, setIframePushStateReady] = useState(false);
  const onIframePushStateReady = useEventCallback(() => {
    setIframePushStateReady(true);
  });

  useEffect(() => {
    const iframeWindow = iframeElement?.contentWindow;
    if (!iframeWindow) {
      if (iframePushStateReady) {
        setIframePushStateReady(false);
      }
      return;
    }
    if (iframePushStateReady) return;
    const abortController = new AbortController();
    window.addEventListener(
      "message",
      (e) => {
        if (!e.data || typeof e.data !== "object") return;
        if (e.data.type !== "embeddedAppMounted") return;
        if (e.source !== iframeElement.contentWindow) return;
        onIframePushStateReady();
      },
      {
        signal: abortController.signal,
      },
    );
    return () => {
      abortController.abort();
    };
  }, [iframeElement, iframePushStateReady, onIframePushStateReady]);

  const getCode = useCallback<() => Promise<string>>(async () => {
    return new Promise((resolve, reject) => {
      if (!iframeElement) return;
      const iframeWindow = iframeElement.contentWindow;
      if (!iframeWindow) return;
      const id = random();
      const abortController = new AbortController();
      window.addEventListener(
        "message",
        (e) => {
          if (!e.data || typeof e.data !== "object") return reject();
          if (e.data.type !== "copyClWebSdkHtml") return reject();
          if (e.data.id !== id) return reject();
          if (typeof e.data.code !== "string") return reject();
          if (e.source !== iframeWindow) return reject();
          abortController.abort();
          resolve(e.data.code);
        },
        {
          signal: abortController.signal,
        },
      );

      setTimeout(() => {
        abortController.abort();
      }, 1000);

      iframeWindow.postMessage(
        {
          type: "copyClWebSdkHtml",
          id,
        },
        iframeElement.src,
      );
    });
  }, [iframeElement]);

  useEffect(() => {
    if (!iframePushStateReady) return;
    if (!iframeElement) return;
    const iframeWindow = iframeElement.contentWindow;
    if (!iframeWindow) return;
    iframeWindow.postMessage(
      {
        type: "pushState",
        url: src,
      },
      iframeElement.src,
    );
  }, [iframeElement, iframePushStateReady, src]);

  return (
    <>
      <Box
        sx={sxPropsStyles.embedCode}
        component="iframe"
        src={initSrc}
        sandbox="allow-scripts allow-same-origin"
        ref={setIframeElement}
      />
      <CopyButton variant="plain" text={getCode} />
    </>
  );
};

const SdkIframe: React.FC<{
  clWebChannelId: string;
  pluginRe: AvailablePluginRe;
}> = ({ pluginRe, clWebChannelId }) => {
  const embeddedPage = useMemo(() => {
    const url = new URL(CL_WEB_SDK_EMBED_CODE_URL);
    url.searchParams.set("clWebChannelId", clWebChannelId);
    url.searchParams.set("pluginRe", pluginRe);
    url.searchParams.set("playgroundColorMode", "light");
    const sx: SxProps = {
      pre: {
        paddingBlock: "8px",
        paddingInline: "12px",
        fontSize: "14px",
      },
    };
    url.searchParams.set("playgroundEmbeddedPageSx", JSON.stringify(sx));
    return url.toString();
  }, [clWebChannelId, pluginRe]);

  return <CodeIframe src={embeddedPage} />;
};

const ShopifyCodeIframe: React.FC<{
  clWebChannelId: string;
}> = ({ clWebChannelId }) => {
  const embeddedPage = useMemo(() => {
    const url = new URL(CL_WEB_SDK_EMBED_SHOPIFY_CODE_URL);
    url.searchParams.set("clWebChannelId", clWebChannelId);
    url.searchParams.set("playgroundColorMode", "light");
    const sx: SxProps = {
      pre: {
        paddingBlock: "8px",
        paddingInline: "12px",
        fontSize: "14px",
      },
    };
    url.searchParams.set("playgroundEmbeddedPageSx", JSON.stringify(sx));
    return url.toString();
  }, [clWebChannelId]);

  return <CodeIframe src={embeddedPage} />;
};

const SelfHostedCode: React.FC = () => {
  const { channel } = ChannelQueriesContext.useData();
  return (
    <Stack direction="column" gap="8px">
      {channel.externalChannelId === null ? (
        <Typography>
          {inspectMessage`Unexpected CL Web ChannelId ${channel.externalChannelId}`}
        </Typography>
      ) : (
        <SdkIframe
          clWebChannelId={channel.externalChannelId}
          pluginRe="dataLayer"
        />
      )}
    </Stack>
  );
};

const ShopifyAdditionalCode: React.FC = () => {
  const { channel } = ChannelQueriesContext.useData();
  if (!channel.externalChannelId)
    return (
      <Typography>
        {inspectMessage`Unexpected CL Web ChannelId ${channel.externalChannelId}`}
      </Typography>
    );

  return (
    <Stack direction="column" gap="8px">
      <Typography>
        <Trans i18nKey="page.channel.wccs.install.sdk.shopify.desc" />
      </Typography>
      <ShopifyCodeIframe clWebChannelId={channel.externalChannelId} />
    </Stack>
  );
};

const EcHostedCode: React.FC<{
  pluginRe: Extract<
    AvailablePluginRe,
    "cyberbiz" | "shopify" | "shopline" | "nineOneApp"
  >;
  description?: React.ReactNode;
  extras?: React.ReactNode;
}> = ({ description, pluginRe, extras }) => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  return (
    <Stack direction="column" gap="8px">
      {description ?? (
        <Typography>
          <Trans i18nKey="page.channel.wccs.install.sdk.ec.desc" />
        </Typography>
      )}
      <Typography>
        <ExternalLink
          trailingIcon
          href={t("page.channel.wccs.install.sdk.howTo.link")}
        >
          <Trans i18nKey="page.channel.wccs.install.sdk.howTo.label" />
        </ExternalLink>
      </Typography>
      {channel.externalChannelId === null ? (
        <Typography>
          {inspectMessage`Unexpected CL Web ChannelId ${channel.externalChannelId}`}
        </Typography>
      ) : (
        <>
          <SdkIframe
            clWebChannelId={channel.externalChannelId}
            pluginRe={pluginRe}
          />
          {extras}
        </>
      )}
    </Stack>
  );
};

const selfHostedStyle = defineStyles({
  icon: css({
    fontSize: "16px",
    color: theme.colors.staticFgBody,
  }),
  howTo: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "12px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    border: `1px solid ${theme.colors.staticFgLine}`,
  }),
});

const SelfHosted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap="16px">
      <Stack direction="column" gap="8px">
        <Typography>
          <Trans i18nKey="page.channel.wccs.install.sdk.selfHosted.desc" />
        </Typography>
        <Typography>
          <ExternalLink
            trailingIcon
            href={t("page.channel.wccs.install.sdk.howTo.link")}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.howTo.label" />
          </ExternalLink>
        </Typography>
      </Stack>
      <SelfHostedCode />
      <div css={selfHostedStyle.howTo}>
        <Stack direction="row" gap="4px" alignItems="center">
          <MotifIcon un-i-motif="lightbulb" css={selfHostedStyle.icon} />
          <Typography fontWeight={500}>
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.title" />
          </Typography>
        </Stack>
        <Typography>
          <Trans i18nKey="page.channel.wccs.install.sdk.tracking.desc" />
        </Typography>
        <Stack direction="row" gap="16px">
          <ExternalLink
            trailingIcon
            href={t(
              "page.channel.wccs.install.sdk.tracking.helpingCenter.link",
            )}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.helpingCenter.label" />
          </ExternalLink>
          <ExternalLink
            trailingIcon
            href={t("page.channel.wccs.install.sdk.tracking.docs.link")}
          >
            <Trans i18nKey="page.channel.wccs.install.sdk.tracking.docs.label" />
          </ExternalLink>
        </Stack>
      </div>
    </Stack>
  );
};

const activationStyle = defineStyles({
  icon: css({
    fontSize: 16,
  }),
});

const Activation: React.FC<{ label: React.ReactNode; enabled: boolean }> = ({
  label,
  enabled,
}) => {
  return (
    <Alert
      severity={enabled ? "success" : "plain"}
      variant="outlined"
      icon={
        enabled ? (
          <MotifIcon un-i-motif="circle_check" css={activationStyle.icon} />
        ) : (
          <MotifIcon un-i-motif="circle_cross" css={activationStyle.icon} />
        )
      }
    >
      {label}
    </Alert>
  );
};

const EcTab = styled(Tab)({
  minWidth: "130px",
  minHeight: "auto",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  padding: "8px 12px",
  borderRadius: "4px",
  border: `1px solid ${theme.colors.neutral030}`,
  "&.Mui-selected": {
    border: `1px solid ${theme.colors.blue060}`,
    backgroundColor: theme.colors.blue010,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:focus": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:active": {
    backgroundColor: theme.colors.blue010,
    border: `1px solid ${theme.colors.blue060}`,
    color: theme.colors.blue060,
  },
  "&.Mui-selected:disabled": {
    backgroundColor: theme.colors.neutral020,
    border: `1px solid ${theme.colors.neutral030}`,
    color: theme.colors.neutral060,
  },
});

const EcTabs = styled(Tabs)({
  minHeight: "auto",
  "& .MuiTabs-flexContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(130px, 1fr))",
    gap: "8px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const ecTabsStyles = defineStyles({
  icon: css({
    fontSize: 16,
  }),
  logo: css({
    fontSize: 20,
  }),
});

const Step3: React.FC = () => {
  const [activeTab, setActiveTab] = useState<AvailablePluginRe>(
    AvailablePluginReSchema.enum.dataLayer,
  );
  const handleChange = useHandler<
    React.ComponentProps<typeof Tabs>["onChange"]
  >((_, newValue) => {
    const result = AvailablePluginReSchema.safeParse(newValue);
    if (!result.success) return;
    setActiveTab(result.data);
  });
  return (
    <Stack direction="column" gap="8px">
      <EcTabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <EcTab
          value={AvailablePluginReSchema.enum.dataLayer}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon un-i-motif="api" css={ecTabsStyles.icon} />
              <Trans i18nKey="page.channel.wccs.install.sdk.selfHosted.label" />
            </Stack>
          }
        />
        <EcTab
          value={AvailablePluginReSchema.enum.shopline}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-shopline_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={AvailablePluginReSchema.enum.nineOneApp}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-nine_one_app_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={AvailablePluginReSchema.enum.shopify}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-shopify_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
        <EcTab
          value={AvailablePluginReSchema.enum.cyberbiz}
          label={
            <Stack direction="row" gap="4px" alignItems="center">
              <MotifIcon
                un-i-motif="logo-cyberbiz_logo"
                css={ecTabsStyles.logo}
              />
            </Stack>
          }
        />
      </EcTabs>
      {activeTab === AvailablePluginReSchema.enum.dataLayer ? (
        <SelfHosted />
      ) : activeTab === AvailablePluginReSchema.enum.nineOneApp ? (
        <EcHostedCode
          pluginRe={activeTab}
          description={
            <Typography>
              <Trans i18nKey="page.channel.wccs.install.sdk.nineOneApp.desc" />
            </Typography>
          }
        />
      ) : activeTab === AvailablePluginReSchema.enum.shopify ? (
        <EcHostedCode pluginRe={activeTab} extras={<ShopifyAdditionalCode />} />
      ) : (
        <EcHostedCode pluginRe={activeTab} />
      )}
    </Stack>
  );
};

const Installation: React.FC = () => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const settingQuery = interlude.organization.useOrganizationSetting();

  if (channel.type !== "wccs" || !settingQuery.isSuccess) return null;

  return (
    <Stack gap="8px">
      <Typography
        variant="h2"
        fontWeight={700}
        color={theme.colors.staticFgTitle}
      >
        <Trans i18nKey="page.channel.install.title" />
      </Typography>
      <BadgeList>
        <BadgeItem
          index={1}
          title={<Trans i18nKey="page.channel.install.domain.title" />}
          content={
            <Stack direction="column" gap="8px">
              <Typography color={theme.colors.staticFgBody}>
                <Trans i18nKey="page.channel.install.domain.desc" />
              </Typography>
              <Domains />
            </Stack>
          }
        />
        <BadgeItem
          index={2}
          title={<Trans i18nKey="page.channel.install.activation.title" />}
          content={
            <Stack direction="column" gap="8px">
              <Typography>
                <Trans i18nKey="page.channel.install.activation.desc" />
              </Typography>
              <Typography fontWeight={500}>
                <Trans i18nKey="channels.wccs.activation.title" />
              </Typography>
              <Activation
                label={t("channels.wccs.activation.webChatModule")}
                enabled={
                  settingQuery.data.wccsWebChatModuleEnabled &&
                  channel.channelInformation.webChatModuleEnabled
                }
              />
              <Activation
                label={t("channels.wccs.activation.webTracking")}
                enabled={
                  settingQuery.data.wccsWebFootprintTrackingEnabled &&
                  channel.channelInformation.webTrackingEnabled
                }
              />
            </Stack>
          }
        />
        <BadgeItem
          index={3}
          title={<Trans i18nKey="page.channel.wccs.install.sdk.title" />}
          content={<Step3 />}
        />
        <BadgeItem
          index={4}
          title={<Trans i18nKey="page.channel.wccs.install.check.title" />}
          content={
            <>
              <Typography>
                <Trans i18nKey="page.channel.wccs.install.check.desc" />
              </Typography>
              <Typography>
                <ExternalLink
                  trailingIcon
                  href={t("page.channel.wccs.install.check.howTo.link")}
                >
                  <Trans i18nKey="page.channel.wccs.install.check.howTo.label" />
                </ExternalLink>
              </Typography>
            </>
          }
        />
      </BadgeList>
    </Stack>
  );
};

export { Installation };
