import "@/init";
import "@/index.css";
import "virtual:uno.css";

import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { App } from "@/App.tsx";

const root = document.getElementById("root");

if (!root) {
  throw new Error("No root element for ReactDOM.createRoot");
}

ReactDOM.createRoot(root).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
