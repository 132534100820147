import { define } from "@chatbotgang/etude/util/define";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { css, type SxProps } from "@mui/material/styles";
import type { muiTheme } from "@polifonia/theme";
import { theme } from "@polifonia/theme";
import { fakeT } from "@polifonia/utils/react/fakeT";
import { useStatsigClient } from "@statsig/react-bindings";
import { Link, useMatches } from "@tanstack/react-router";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FeatureFlagTypes } from "@/features/featureFlag";
import { useGetFeatureFlag } from "@/features/featureFlag";

const styles = {
  List: (muiTheme) => ({
    padding: muiTheme.spacing(2, 2),
    display: "flex",
    flexDirection: "column",
    gap: muiTheme.spacing(1),
  }),
  ListItemButton: {
    padding: "4px 12px",
    height: "32px",
    color: theme.colors.neutral090,
    borderRadius: "4px",
    "&.Mui-selected": {
      color: theme.colors.primary,
      backgroundColor: theme.colors.blue020,
    },
    "&.Mui-disabled": {
      color: theme.colors.neutral060,
    },
  },
} satisfies Record<string, SxProps<typeof muiTheme>>;

const cssLink = css({
  textDecoration: "none",
});

interface DrawerMenuListItemProps {
  path: string;
  label: string;
}

const DrawerMenuListItem: React.FC<DrawerMenuListItemProps> = ({
  path,
  label,
}) => {
  const matches = useMatches();
  const selected = useMemo(
    () => matches.some((match) => match.pathname.startsWith(path)),
    [matches, path],
  );

  return (
    <Link to={path} css={cssLink}>
      <ListItem disablePadding>
        <ListItemButton
          component="button"
          selected={selected}
          sx={styles.ListItemButton}
        >
          <ListItemText primary={label} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

const t = fakeT;

const menuItems = {
  applications: {
    path: "/applications",
    labelTranslationKey: t("sideMenu.applications"),
  } as const,
  billing: {
    path: "/billing",
    labelTranslationKey: t("sideMenu.billing"),
  } as const,
  channels: {
    path: "/channels",
    labelTranslationKey: t("sideMenu.channels"),
  } as const,
  userManagement: {
    path: "/user-management",
    labelTranslationKey: t("sideMenu.userManagement"),
  } as const,
} satisfies Record<
  "applications" | "billing" | "channels" | "userManagement",
  {
    path: string;
    labelTranslationKey: string;
  }
>;

const drawerItems = define<
  Array<{
    path: keyof typeof menuItems;
    requireFeatureFlag?: Array<FeatureFlagTypes["ToggleKey"]>;
    requireCheckGate?: Array<string>;
  }>
>([
  {
    path: "userManagement",
    requireCheckGate: ["user_management"],
  },
  {
    path: "applications",
  },
  {
    path: "billing",
  },
  {
    path: "channels",
    requireFeatureFlag: ["showChannelsPage"],
    requireCheckGate: [
      "whatsapp_channel_integration",
      "wccs_channel_integration",
    ],
  },
]);

const DrawerMenuList: React.FC = () => {
  const { t } = useTranslation();
  const client = useStatsigClient();
  const getFeatureFlag = useGetFeatureFlag();
  const items = useMemo(() => {
    return drawerItems
      .filter(function checkPermission(route) {
        if (!route.requireFeatureFlag && !route.requireCheckGate) return true;
        return (
          route.requireCheckGate?.some((key) => client.checkGate(key)) ||
          route.requireFeatureFlag?.some(getFeatureFlag)
        );
      })
      .map((route) => ({
        path: menuItems[route.path].path,
        label: t(menuItems[route.path].labelTranslationKey),
      }));
  }, [getFeatureFlag, client, t]);
  return (
    <List sx={styles.List}>
      {items.map((item) => (
        <DrawerMenuListItem
          key={item.path}
          path={item.path}
          label={item.label}
        />
      ))}
    </List>
  );
};

export { DrawerMenuList };
