import { useSnackbar } from "notistack";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { interlude } from "@/interlude";
import { ChannelIcon } from "@/pages/channels/components/ChannelIcon";
import { ChannelNameCard } from "@/pages/channels/components/ChannelNameCard";
import { ExternalChannelId } from "@/pages/channels/components/ExternalChannelId";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";

export const ChannelNameEdit: FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { channel } = ChannelQueriesContext.useData();

  const updateMutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        enqueueSnackbar(t("common.updatedSuccessfully"), {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(t("common.apiError.unexpectedError"), {
          variant: "error",
        });
      },
    },
  );

  return (
    <ChannelNameCard
      value={channel.name}
      onSubmit={async (name) => {
        await updateMutation.mutateAsync({ name });
      }}
      disabled={updateMutation.isPending}
      icon={<ChannelIcon type={channel.type} />}
      description={<ExternalChannelId />}
    />
  );
};
