import { useHandler } from "@chatbotgang/etude/react/useHandler";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { theme } from "@polifonia/theme";
import useSwitch from "@react-hook/switch";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import { interlude, interludeClient } from "@/interlude";
import { ChannelQueriesContext } from "@/pages/channels/pages/detail/channelQueriesContext";
import { ChannelNameEdit } from "@/pages/channels/pages/detail/components/ChannelNameEdit";

const ConnectChannel: React.FC = () => {
  const { t } = useTranslation();
  const [open, toggle] = useSwitch(false);
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        toggle.off();
        enqueueSnackbar(
          t("page.channel.config.connection.connect.success.message"),
          {
            variant: "success",
          },
        );
      },
    },
  );

  const onOk = useHandler(function onOk() {
    mutation.mutate({
      status: "connected",
    });
  });

  return (
    <>
      <Button variant="primary" onClick={toggle.on}>
        <Trans i18nKey="page.channel.config.connection.connect.button.label" />
      </Button>
      <Modal
        open={open}
        handleClose={toggle.off}
        title={t("page.channel.config.connection.connect.modal.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="16px">
            <Button
              variant="plain"
              onClick={toggle.off}
              disabled={mutation.isPending}
            >
              {t("common.cancel")}
            </Button>
            <Button
              onClick={onOk}
              variant="primary"
              loading={mutation.isPending}
              disabled={mutation.isPending}
            >
              {t("page.channel.config.connection.connect.button.label")}
            </Button>
          </Stack>
        }
      >
        <Trans i18nKey="page.channel.config.connection.connect.modal.desc" />
      </Modal>
    </>
  );
};

const DisconnectChannel: React.FC = () => {
  const { t } = useTranslation();
  const [open, toggle] = useSwitch(false);
  const { channel } = ChannelQueriesContext.useData();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = interlude.channel.useUpdateChannel(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      onSuccess() {
        toggle.off();
        enqueueSnackbar(
          t("page.channel.config.connection.disconnect.success.message"),
          {
            variant: "success",
          },
        );
      },
    },
  );

  const onOk = useHandler(function onOk() {
    mutation.mutate({
      status: "disconnected",
    });
  });

  return (
    <>
      <Button variant="plain" color="error" onClick={toggle.on}>
        <Trans i18nKey="page.channel.config.connection.disconnect.button.label" />
      </Button>
      <Modal
        open={open}
        handleClose={toggle.off}
        title={t("page.channel.config.connection.disconnect.modal.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="16px">
            <Button
              variant="plain"
              onClick={toggle.off}
              disabled={mutation.isPending}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="danger"
              onClick={onOk}
              loading={mutation.isPending}
              disabled={mutation.isPending}
            >
              {t("page.channel.config.connection.disconnect.button.label")}
            </Button>
          </Stack>
        }
      >
        <Trans i18nKey="page.channel.config.connection.disconnect.modal.desc" />
      </Modal>
    </>
  );
};

const CheckTrackingConnection: React.FC = () => {
  const { t } = useTranslation();
  const { channel } = ChannelQueriesContext.useData();
  const [openSuccess, toggleSuccess] = useSwitch(false);
  const [openWarning, toggleWarning] = useSwitch(false);
  const { enqueueSnackbar } = useSnackbar();
  const query = interlude.channel.useGetById(
    {
      params: {
        channelId: channel.id,
      },
    },
    {
      enabled: false,
    },
  );

  const mutation = useMutation({
    mutationFn: () =>
      interludeClient.channel.getTrackingConnectionStatus({
        params: {
          channelId: channel.id,
        },
      }),
    onSuccess(result) {
      query.invalidate();
      if (result.status !== "connected") {
        toggleWarning.on();
        return;
      }
      toggleSuccess.on();
    },
    onError() {
      enqueueSnackbar(t("page.channel.config.tracking.error.desc"), {
        variant: "error",
      });
    },
  });

  const checkData = useHandler(function checkData() {
    if (mutation.isPending) return;
    mutation.mutate();
  });

  return (
    <>
      <Button
        variant="plain"
        disabled={channel.status !== "connected" || mutation.isPending}
        loading={mutation.isPending}
        onClick={checkData}
      >
        <Trans i18nKey="page.channel.config.tracking.button" />
      </Button>
      <Modal.Success
        open={openSuccess}
        handleClose={toggleSuccess.off}
        title={t("page.channel.config.tracking.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="12px">
            <Button variant="primary" onClick={toggleSuccess.off}>
              <Trans i18nKey="page.channel.config.tracking.ok" />
            </Button>
          </Stack>
        }
      >
        <Box width="100%" textAlign="left">
          <Typography variant="body" color={theme.colors.staticFgBody}>
            <Trans i18nKey="page.channel.config.tracking.success.desc" />
          </Typography>
        </Box>
      </Modal.Success>
      <Modal.Warning
        open={openWarning}
        handleClose={toggleWarning.off}
        title={t("page.channel.config.tracking.title")}
        footer={
          <Stack direction="row" justifyContent="flex-end" gap="12px">
            <Button
              variant="plain"
              href={t("page.channel.config.tracking.learnMore.link")}
            >
              <Trans i18nKey="page.channel.config.tracking.learnMore.label" />
            </Button>
            <Button variant="primary" onClick={toggleWarning.off}>
              <Trans i18nKey="page.channel.config.tracking.ok" />
            </Button>
          </Stack>
        }
      >
        <Box width="100%" textAlign="left">
          <Typography variant="body" color={theme.colors.staticFgBody}>
            <Trans i18nKey="page.channel.config.tracking.warning.desc" />
          </Typography>
        </Box>
      </Modal.Warning>
    </>
  );
};

const General: React.FC = () => {
  const { channel } = ChannelQueriesContext.useData();
  return (
    <Stack direction="column" gap={3}>
      <Stack direction="column" gap={1}>
        <Typography
          variant="h3"
          fontWeight={500}
          color={theme.colors.staticFgTitle}
        >
          <Trans i18nKey="page.channel.channelName.title" />
        </Typography>
        <ChannelNameEdit />
      </Stack>
      <Stack direction="column" gap={1} maxWidth="776px" width="100%">
        <Typography
          variant="h3"
          fontWeight={500}
          color={theme.colors.staticFgTitle}
        >
          <Trans i18nKey="page.channel.language.title" />
        </Typography>
        <Typography variant="body" color={theme.colors.staticFgBody}>
          <Trans i18nKey="page.channel.language.desc" />
        </Typography>
      </Stack>
      <Stack direction="column" gap={1}>
        <Typography
          variant="h3"
          fontWeight={500}
          color={theme.colors.staticFgTitle}
        >
          <Trans i18nKey="page.channel.config.title" />
        </Typography>
        <Stack direction="column" gap={2}>
          <Stack direction="column" gap={1}>
            <Typography
              variant="body"
              fontWeight={500}
              color={theme.colors.staticFgTitle}
            >
              <Trans i18nKey="page.channel.config.tracking.title" />
            </Typography>
            <Typography variant="body" color={theme.colors.staticFgBody}>
              <Trans i18nKey="page.channel.config.tracking.desc" />
            </Typography>
            <CheckTrackingConnection />
          </Stack>
          <Stack direction="column" gap={1}>
            <Typography
              variant="body"
              fontWeight={500}
              color={theme.colors.staticFgTitle}
            >
              <Trans i18nKey="page.channel.config.connection.title" />
            </Typography>
            <Typography variant="body" color={theme.colors.staticFgBody}>
              <Trans i18nKey="page.channel.config.connection.desc" />
            </Typography>
            {channel.status === "disconnected" ? (
              <ConnectChannel />
            ) : (
              <DisconnectChannel />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { General };
