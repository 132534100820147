import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { css } from "@emotion/react";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { theme } from "@polifonia/theme";
import { useSnackbar } from "notistack";
import { type FC, useMemo } from "react";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import { ExternalLink } from "@/components/ExternalLink";
import { Modal } from "@/components/Modal";
import { Select } from "@/components/Select";
import { Typography } from "@/components/Typography";
import { useUserPermission } from "@/features/accessManagement/useUserPermission";
import type { InterludeTypes } from "@/interlude";
import { interlude } from "@/interlude";
import { defineStyles } from "@/internal/emotion";

const styles = defineStyles({
  input: css({
    backgroundColor: theme.colors.white,
  }),
});

interface EditModalProps {
  open: boolean;
  onClose: () => void;
  orgSource: InterludeTypes["OrgSource"];
  roleOptions: Array<{
    label: string;
    value: string;
  }>;
  targetUser: InterludeTypes["User"] | null;
}

export const EditModal: FC<EditModalProps> = ({
  open,
  onClose,
  orgSource,
  roleOptions,
  targetUser,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const updateUserMutation = interlude.user.useUpdateUser();
  const { email } = useUserPermission();

  const isEditingMe = useMemo(() => {
    return email === targetUser?.email;
  }, [email, targetUser?.email]);

  const allRoles = useMemo(() => {
    const rolesNotEditable =
      targetUser?.roles
        .filter((role) => !role.name.startsWith(orgSource))
        .map((role) => role.id) ?? [];
    const rolesEditable =
      targetUser?.roles
        .filter((role) => role.name.startsWith(orgSource))
        .map((role) => role.id) ?? [];
    return {
      rolesNotEditable,
      rolesEditable,
    };
  }, [orgSource, targetUser?.roles]);

  const form = useForm({
    defaultValues: {
      email: targetUser?.email ?? "",
      username: targetUser?.username ?? "",
      role: allRoles.rolesEditable[0] ?? "",
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const role = useWatch({ control, name: "role" });

  useEffect(
    function syncTargetUserToForm() {
      if (targetUser) {
        form.reset({
          email: targetUser.email,
          username: targetUser.username ?? "",
          role: allRoles.rolesEditable[0] ?? "",
        });
      }
    },
    [targetUser, form, allRoles.rolesEditable],
  );
  return (
    <Modal
      title={t("userManagement.editModal.title")}
      open={open}
      handleClose={onClose}
      footer={
        <Stack gap="12px" direction="row" justifyContent="flex-end">
          <Button key="cancel" variant="secondary" onClick={onClose}>
            {t("userManagement.editModal.cancel")}
          </Button>
          <Button
            key="save"
            variant="primary"
            onClick={handleSubmit(async (data) => {
              const { error } = await safePromise(async () =>
                updateUserMutation.mutateAsync({
                  email: data.email,
                  username: data.username,
                  roleIds: [data.role, ...allRoles.rolesNotEditable],
                }),
              );
              if (error) {
                enqueueSnackbar(t("userManagement.editUser.failure"), {
                  variant: "error",
                });
                return;
              }
              enqueueSnackbar(t("userManagement.editUser.success"), {
                variant: "success",
              });
              onClose();
            })}
            loading={isSubmitting}
            disabled={!role}
          >
            {t("userManagement.editModal.update")}
          </Button>
        </Stack>
      }
    >
      <Stack direction="column" gap="16px">
        <Stack direction="column" gap="8px">
          <Typography fontWeight={500}>
            {t("userManagement.editModal.email")}
          </Typography>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField {...field} css={styles.input} disabled={true} />
            )}
          />
        </Stack>
        <Stack direction="column" gap="8px">
          <Typography fontWeight={500}>
            {t("userManagement.editModal.roles")}
          </Typography>
          <ExternalLink
            href={t("userManagement.editModal.maacRoleTable.externalLink")}
            target="_blank"
            trailingIcon={true}
            style={{
              fontSize: "14px",
              width: "fit-content",
            }}
          >
            {t("userManagement.editModal.maacRoleTable")}
          </ExternalLink>
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <Select
                multiple={false}
                disableClearable={true}
                {...field}
                disabled={isEditingMe}
                placeholder={
                  field.value.length <= 0
                    ? t("userManagement.editModal.selectRoles")
                    : ""
                }
                options={roleOptions}
              />
            )}
          />
        </Stack>
      </Stack>
    </Modal>
  );
};
