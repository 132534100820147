import { useHandler } from "@chatbotgang/etude/react/useHandler";
import CheckIcon from "@mui/icons-material/Check";
import type { SxProps } from "@mui/material";
import type { AutocompleteProps } from "@mui/material/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import type { TextFieldProps } from "@mui/material/TextField";
import TextField from "@mui/material/TextField";
import { theme } from "@polifonia/theme";
import objectInspect from "object-inspect";
import { useMemo } from "react";

import { KeyboardArrowDownIcon } from "@/components/Icons/KeyboardArrowDownIcon";
import { Tag } from "@/components/Tag";

function getKey(value: string | number | Record<string, unknown>) {
  return typeof value === "object" ? objectInspect(value) : value;
}

const getAutocompleteSx = (disabled: boolean): SxProps => ({
  ".MuiAutocomplete-inputRoot": {
    padding: "2px 6px",
    borderRadius: theme.shape.borderRadius,
    cursor: disabled ? "not-allowed" : "pointer",
  },
  ".MuiOutlinedInput-root .MuiAutocomplete-input": {
    padding: "0 4px",
    cursor: disabled ? "not-allowed" : "pointer",
  },
});

const TextFieldSx: SxProps = {
  ".MuiInputBase-root": {
    gap: "10px",
    minHeight: "35px",
  },
  ".MuiOutlinedInput-root.Mui-disabled": {
    backgroundColor: theme.colors.staticBgDisable,
  },
  ".MuiOutlinedInput-root:not(.Mui-error,.Mui-disabled):hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: theme.colors.staticFgLine,
    },
};

export type SelectValue = {
  label?: React.ReactNode;
  value: string | number | Record<string, unknown>;
};

export interface BaseSelectProps<Value extends SelectValue>
  extends Omit<
      AutocompleteProps<Value, boolean, false, false>,
      | "renderTags"
      | "renderInput"
      | "popupIcon"
      | "freeSolo"
      | "disableClearable"
      | "getOptionLabel"
      | "renderOption"
      | "onChange"
      | "value"
      | "defaultValue"
      | "multiple"
    >,
    Pick<TextFieldProps, "helperText" | "error"> {
  placeholder?: string;
  disabled?: boolean;
  disableClearable?: boolean;
}

type SelectComponent = <
  ValueType extends string | number | Record<string, unknown>,
  Value extends SelectValue = SelectValue,
>(
  props:
    | SingleSelect.Props<ValueType, Value>
    | MultipleSelect.Props<ValueType, Value>,
) => React.ReactNode;

const Select: SelectComponent = <
  ValueType extends string | number | Record<string, unknown>,
  Value extends SelectValue = SelectValue,
>(
  props:
    | SingleSelect.Props<ValueType, Value>
    | MultipleSelect.Props<ValueType, Value>,
) => {
  if (props.multiple === true) {
    return <MultipleSelect<ValueType, Value> {...props} />;
  } else {
    return <SingleSelect<ValueType, Value> {...props} />;
  }
};

namespace SingleSelect {
  export interface Props<
    ValueType extends string | number | null | Record<string, unknown>,
    Value extends SelectValue = SelectValue,
  > extends BaseSelectProps<Value> {
    multiple?: false | undefined;
    value?: ValueType;
    onChange?: ((value: ValueType) => void) | undefined;
  }
  export interface Type {
    <
      ValueType extends string | number | null | Record<string, unknown>,
      Value extends SelectValue = SelectValue,
    >(
      props: Props<ValueType, Value>,
    ): React.ReactNode;
  }
}

const SingleSelect: SingleSelect.Type = <
  ValueType extends string | number | null | Record<string, unknown>,
  Value extends SelectValue = SelectValue,
>({
  value,
  onChange,
  options,
  placeholder = "",
  helperText,
  error,
  disabled = false,
  multiple,
  disableClearable,
  ...rest
}: SingleSelect.Props<ValueType, Value>) => {
  type SingleAutocompleteProps = AutocompleteProps<Value, false, false, false>;

  const autoCompleteValue = useMemo(() => {
    return value !== null && value !== undefined
      ? options.find((o) => o.value === value) || null
      : null;
  }, [value, options]);

  const handleChange = useHandler<SingleAutocompleteProps["onChange"]>(
    (_event, value) => {
      if (onChange) {
        onChange(value ? (value.value as ValueType) : (null as ValueType));
      }
    },
  );

  const autocompleteProps = {
    ...rest,
    options,
    value: autoCompleteValue,
    onChange: handleChange,
    disabled,
    multiple: false,
    disableCloseOnSelect: false,
    disableClearable,
  } as unknown as SingleAutocompleteProps;

  return (
    <Autocomplete
      {...autocompleteProps}
      renderInput={(params) => (
        // @ts-expect-error The types of 'InputLabelProps.className' are incompatible between these types.
        <TextField
          {...params}
          size="medium"
          variant="outlined"
          placeholder={placeholder}
          error={!!error}
          disabled={disabled}
          helperText={helperText}
          sx={TextFieldSx}
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
      renderOption={(optionProps, option, { selected }) => (
        // @ts-expect-error props are incompatible
        <MenuItem
          {...optionProps}
          key={getKey(option.value)}
          value={option}
          sx={{
            "&.MuiAutocomplete-option": {
              fontSize: "0.875rem",
              paddingTop: "4px",
              paddingBottom: "4px",
            },
          }}
        >
          <div>{option.label}</div>
          {selected ? (
            <CheckIcon color="primary" sx={{ marginLeft: "auto" }} />
          ) : null}
        </MenuItem>
      )}
      sx={getAutocompleteSx(disabled)}
    />
  );
};

namespace MultipleSelect {
  export type Props<
    ValueType extends string | number | null | Record<string, unknown>,
    Value extends SelectValue = SelectValue,
  > = Omit<BaseSelectProps<Value>, "maxCount" | "showCount"> & {
    multiple: true;
    value?: Array<ValueType> | undefined;
    onChange?: ((value: Array<ValueType>) => void) | undefined;
  } & (
      | {
          /**
           * The maximum number of selected options
           */
          maxCount: number;
          /**
           * Whether to show the count of selected options.
           * Can only be used when maxCount is specified.
           */
          showCount?: boolean;
        }
      | {
          maxCount?: number;
          showCount?: never;
        }
    );
  export interface Type {
    <
      ValueType extends string | number | null | Record<string, unknown>,
      Value extends SelectValue = SelectValue,
    >(
      props: Props<ValueType, Value>,
    ): React.ReactNode;
  }
}

const MultipleSelect: MultipleSelect.Type = <
  ValueType extends string | number | null | Record<string, unknown>,
  Value extends SelectValue = SelectValue,
>({
  value,
  onChange,
  options,
  placeholder = "",
  helperText,
  error,
  maxCount = Infinity,
  showCount = false,
  disabled = false,
  multiple,
  disableClearable,
  ...rest
}: MultipleSelect.Props<ValueType, Value>) => {
  type MultipleAutocompleteProps = AutocompleteProps<Value, true, false, false>;
  const autoCompleteValue = useMemo(() => {
    return (
      value?.map((v) => options.find((o) => o.value === v)).filter(Boolean) ??
      []
    );
  }, [value, options]);

  const handleChange = useHandler<MultipleAutocompleteProps["onChange"]>(
    (_event, value, reason) => {
      if (reason === "selectOption" && value.length > maxCount) {
        return;
      }
      if (onChange) {
        onChange(value.map((v) => v.value as ValueType));
      }
    },
  );

  const autocompleteProps = {
    ...rest,
    options,
    value: autoCompleteValue,
    onChange: handleChange,
    disabled,
    multiple: true,
    disableCloseOnSelect: true,
  } as unknown as MultipleAutocompleteProps;

  return (
    <Autocomplete
      {...autocompleteProps}
      renderInput={(params) => (
        // @ts-expect-error The types of 'InputLabelProps.className' are incompatible between these types.
        <TextField
          {...params}
          size="medium"
          variant="outlined"
          placeholder={autoCompleteValue.length <= 0 ? placeholder : undefined}
          error={!!error}
          disabled={disabled}
          helperText={
            <Stack direction="row" justifyContent="space-between">
              <span>{helperText}</span>
              {showCount && maxCount !== Infinity && (
                <span>{`${autoCompleteValue.length}/${maxCount}`}</span>
              )}
            </Stack>
          }
          sx={TextFieldSx}
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map(({ value, label }, index) => {
          const { onDelete } = getTagProps({ index });
          return (
            <Tag
              key={getKey(value)}
              label={label}
              onDelete={onDelete}
              sx={{ margin: 0.25, height: "24px" }}
              disabled={disabled}
            />
          );
        })
      }
      popupIcon={<KeyboardArrowDownIcon />}
      renderOption={(optionProps, option, { selected }) => {
        const disabled = !selected && value && value.length >= maxCount;

        return (
          // @ts-expect-error props are incompatible
          <MenuItem
            {...optionProps}
            key={getKey(option.value)}
            value={option}
            disabled={disabled}
            sx={{
              "&.MuiAutocomplete-option": {
                fontSize: "0.875rem",
                paddingTop: "4px",
                paddingBottom: "4px",
              },
              "&.Mui-disabled": {
                opacity: 0.3,
              },
            }}
          >
            <div>{option.label}</div>
            {selected ? (
              <CheckIcon color="primary" sx={{ marginLeft: "auto" }} />
            ) : null}
          </MenuItem>
        );
      }}
      sx={getAutocompleteSx(disabled)}
    />
  );
};

export { MultipleSelect, Select, SingleSelect };
