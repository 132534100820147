import { Typography } from "@/components/Typography";

const defaultComponent = "h1" as const;

namespace PageTitle {
  export type DefaultComponent = typeof defaultComponent;
  export type Props = Exclude<
    Typography.Props<DefaultComponent>,
    "variant" | "as"
  >;
  export type Type = React.FC<Props>;
}

const PageTitle: PageTitle.Type = (props) => {
  return <Typography fontWeight={700} {...props} variant="h1" />;
};

export { PageTitle };
