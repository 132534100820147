import { useMemo } from "react";

import { useGetErrorMessage } from "@/features/error/handleError";

/**
 * Display an error message.
 *
 * example:
 * ```tsx
 * const query = useQuery("key", fetcher);
 * if (query.isError) <ErrorMsg error={query.error} />
 * ```
 */
const ErrorMsg: React.FC<{ error: unknown }> = ({ error }) => {
  const getErrorMessage = useGetErrorMessage();
  const display = useMemo(
    () => getErrorMessage(error),
    [error, getErrorMessage],
  );
  return display;
};

export { ErrorMsg };
