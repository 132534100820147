import type { SxProps } from "@mui/material";
import {
  css,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { theme } from "@polifonia/theme";
import { useStatsigClient } from "@statsig/react-bindings";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/Badge";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Modal";
import { MotifIcon } from "@/components/MotifIcon";
import { Typography } from "@/components/Typography";
import type { InterludeTypes } from "@/interlude";
import { defineStyles } from "@/internal/emotion";

type ChannelTypes = InterludeTypes["Channel"]["type"];

const isValidChannelType = (value: string): value is ChannelTypes => {
  return value === "whatsapp" || value === "wccs";
};

interface ChannelSelectModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (channelType: ChannelTypes) => void;
  loading?: boolean;
  wccsAvailableChannelCount?: number;
}

const styles = {
  RadioGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
} satisfies Record<string, SxProps>;

export const ChannelSelectModal: React.FC<ChannelSelectModalProps> = ({
  open,
  onClose,
  onSubmit,
  loading = false,
  wccsAvailableChannelCount = 0,
}) => {
  const { t } = useTranslation();
  const [channelType, setChannelType] = useState<ChannelTypes>("whatsapp");
  const { client } = useStatsigClient();
  const enableWccs = client.checkGate("wccs_channel_integration");
  const enableWhatsApp = client.checkGate("whatsapp_channel_integration");
  const handleChannelTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (isValidChannelType(value)) {
      setChannelType(value);
    }
  };

  const handleOpenNewRequestClick = () => {
    window.open(t("newRequest.link"), "_blank");
  };

  return (
    <Modal
      open={open}
      handleClose={onClose}
      title={t("channels.addModal.title")}
      footer={
        <Stack direction="row" justifyContent="flex-end" gap="12px">
          <Button variant="plain" onClick={onClose} disabled={loading}>
            {t("channels.addModal.footer.cancel")}
          </Button>
          {channelType === "wccs" && wccsAvailableChannelCount === 0 ? (
            <Button variant="primary" onClick={handleOpenNewRequestClick}>
              {t("channels.addModal.footer.contactUs")}
            </Button>
          ) : (
            <Button
              variant="primary"
              loading={loading}
              onClick={() => onSubmit(channelType)}
            >
              {t("channels.addModal.footer.continue")}
            </Button>
          )}
        </Stack>
      }
    >
      <Stack gap="16px">
        <FormControl>
          <RadioGroup
            name="channel-buttons-group"
            value={channelType}
            onChange={handleChannelTypeChange}
            sx={styles.RadioGroup}
          >
            {!enableWhatsApp ? null : (
              <FormControlLabel
                value="whatsapp"
                control={<Radio />}
                label={
                  <ChannelRadioGroupOption
                    channelType="whatsapp"
                    title="Whatsapp"
                  />
                }
              />
            )}
            {!enableWccs ? null : (
              <FormControlLabel
                value="wccs"
                control={<Radio />}
                label={
                  <ChannelRadioGroupOption
                    channelType="wccs"
                    title={t("channels.addModal.body.channel.wccs.title")}
                    titleBadge={<Badge>New</Badge>}
                    description={t(
                      "channels.addModal.body.channel.wccs.description",
                    )}
                  />
                }
              />
            )}
          </RadioGroup>
        </FormControl>
        <Typography variant="body" color={theme.colors.staticFgBody}>
          {t("channels.description")}
        </Typography>
      </Stack>
    </Modal>
  );
};

const iconStyles = defineStyles({
  icon: css({
    fontSize: "40px",
  }),
  wccs: css({
    color: theme.colors.staticFgPrimary,
  }),
});

const ChannelRadioGroupOption: React.FC<{
  channelType: ChannelTypes;
  title: string;
  titleBadge?: React.ReactNode;
  description?: string;
}> = ({ channelType, title, titleBadge, description }) => {
  return (
    <Stack direction="row" gap="10px" alignItems="center">
      {channelType === "whatsapp" ? (
        <MotifIcon un-i-motif="whatsapp" css={iconStyles.icon} />
      ) : null}
      {channelType === "wccs" ? (
        <MotifIcon
          un-i-motif="earth"
          css={css([iconStyles.icon, iconStyles.wccs])}
        />
      ) : null}
      <Stack gap="4px">
        <Stack direction="row" gap="8px" alignItems="center">
          <Typography
            variant="body"
            fontWeight={700}
            color={theme.colors.staticFgBody}
          >
            {title}
          </Typography>
          {titleBadge}
        </Stack>
        {description ? (
          <Typography variant="body" color={theme.colors.staticFgNote}>
            {description}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
};
