import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import { InputAdornment, TextField, type TextFieldProps } from "@mui/material";
import { theme } from "@polifonia/theme";

import { Button } from "@/components/Button";
import { MotifIcon } from "@/components/MotifIcon";
import { defineStyles } from "@/internal/emotion";

const styles = defineStyles({
  searchInput: css({
    ".MuiInputBase-root": {
      paddingRight: 0,
      height: 30,
      minWidth: 250,
    },
  }),
  iconButton: css({
    minWidth: "auto",
    color: theme.colors.staticFgBody,
    span: {
      marginRight: 0,
    },
  }),
});

interface SearchInputProps extends Omit<TextFieldProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
  /**
   * Callback function that is called when the search icon is clicked or the Enter key is pressed.
   * @param value - The search string.
   */
  onSearch?: (value: string) => void;
}

/**
 * Controlled input with search and clear buttons.
 * You can get the search string from the input value while the Enter key is pressed or the search icon is clicked.
 * All [MUI TextField](https://mui.com/material-ui/react-text-field/) props are supported except for the following:
 * - `value`
 * - `onChange`
 * - `onKeyDown`
 * - `slotProps.input.endAdornment`
 */
export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onSearch,
  ...textFieldProps
}) => {
  const handleChange = useHandler<TextFieldProps["onChange"]>((event) => {
    onChange(event.target.value);
  });

  const handleClear = useHandler(() => {
    onChange("");
  });

  const handleSearch = useHandler(() => {
    onSearch?.(value);
  });

  const handleKeyDown = useHandler<TextFieldProps["onKeyDown"]>((event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  });

  return (
    <TextField
      css={styles.searchInput}
      {...textFieldProps}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              {value && (
                <Button
                  variant="transparent"
                  css={styles.iconButton}
                  startIcon={<MotifIcon un-i-motif="cross" />}
                  onClick={handleClear}
                />
              )}
              <Button
                variant="transparent"
                css={styles.iconButton}
                startIcon={<MotifIcon un-i-motif="magnifier" />}
                onClick={handleSearch}
              />
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
