import type { ComponentProps } from "@chatbotgang/etude/emotion-react/ComponentProps";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { theme } from "@polifonia/theme";
import { getRouteApi, Link } from "@tanstack/react-router";
import { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { BarLoading } from "@/components/Loading/BarLoading";
import { Typography } from "@/components/Typography";
import { Trans } from "@/features/i18n/Trans";
import { defineStyles } from "@/internal/emotion";
import { PageErrorBoundary } from "@/layout/ErrorBoundary/PageErrorBoundary";
import { ChannelQueriesContextProvider } from "@/pages/channels/pages/detail/channelQueriesContext";
import { General } from "@/pages/channels/pages/detail/wccs/General";
import { Installation } from "@/pages/channels/pages/detail/wccs/Installation";
import { Tools } from "@/pages/channels/pages/detail/wccs/Tools";
import { PageTitle } from "@/pages/components/PageTitle";

const route = getRouteApi("/channels/$type/$id");
const TabsSchema = z.enum(["general", "tools", "installation"]);
type TabsSchemaType = z.infer<typeof TabsSchema>;

interface TabPanelProps extends ComponentProps<"div"> {
  index: TabsSchemaType;
  value: TabsSchemaType;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
    </div>
  );
};

const styles = defineStyles({
  link: css({
    textDecoration: "none",
  }),
  pageTitle: css({
    marginBottom: 0,
  }),
});

const WccsDetailPageInternal: React.FC = () => {
  const { t } = useTranslation();
  const { id } = route.useParams();
  const [value, setValue] = useState<TabsSchemaType>(TabsSchema.Enum.general);
  const handleChange = useHandler<ComponentProps<typeof Tabs>["onChange"]>(
    (_, newValue) => {
      const result = TabsSchema.safeParse(newValue);
      if (!result.success) return;
      setValue(result.data);
    },
  );

  return (
    <Stack gap="8px">
      <Breadcrumbs aria-label="breadcrumb" separator=">">
        <Link to="/channels" css={styles.link}>
          <Typography variant="body" color={theme.colors.staticFgBody}>
            {t("channels.title")}
          </Typography>
        </Link>
        <Link
          to="/channels/$type/$id"
          params={{
            type: "wccs",
            id,
          }}
          css={styles.link}
        >
          <Typography variant="body" color={theme.colors.staticFgNote}>
            {t("page.channel.breadcrumb.wccs")}
          </Typography>
        </Link>
      </Breadcrumbs>
      <PageTitle css={styles.pageTitle}>
        <Trans i18nKey="channels.wccs.title" />
      </PageTitle>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label={<Trans i18nKey="page.channel.tabs.general" />}
          value={TabsSchema.Values.general}
        />
        <Tab
          label={<Trans i18nKey="page.channel.tabs.tools" />}
          value={TabsSchema.Values.tools}
        />
        <Tab
          label={<Trans i18nKey="page.channel.tabs.install" />}
          value={TabsSchema.Values.installation}
        />
      </Tabs>
      <TabPanel value={value} index={TabsSchema.Enum.general}>
        <General />
      </TabPanel>
      <TabPanel value={value} index={TabsSchema.Enum.tools}>
        <Tools />
      </TabPanel>
      <TabPanel value={value} index={TabsSchema.Enum.installation}>
        <Installation />
      </TabPanel>
    </Stack>
  );
};

const WccsDetailPage: React.FC = () => {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<BarLoading />}>
        <ChannelQueriesContextProvider>
          <WccsDetailPageInternal />
        </ChannelQueriesContextProvider>
      </Suspense>
    </PageErrorBoundary>
  );
};

export { WccsDetailPage };
