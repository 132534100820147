import type { MaacPermission } from "@polifonia/interlude/models";
import { useCallback, useMemo } from "react";

import { interlude } from "@/interlude";

function hasPermission(
  userPermissions: Array<MaacPermission>,
  permissionName: MaacPermission,
) {
  return userPermissions.includes(permissionName);
}

export const useUserPermission = () => {
  const myPermissionsQuery = interlude.user.useMyPermissions();

  const checkUserHasPermission = useCallback(
    (permissionName: MaacPermission) => {
      return hasPermission(
        myPermissionsQuery.data?.permissions ?? [],
        permissionName,
      );
    },
    [myPermissionsQuery.data?.permissions],
  );

  return useMemo(() => {
    return {
      email: myPermissionsQuery.data?.email,
      permissionLoading: myPermissionsQuery.isPending,
      permissions: myPermissionsQuery.data?.permissions,
      hasPermission: checkUserHasPermission,
    };
  }, [
    checkUserHasPermission,
    myPermissionsQuery.data?.email,
    myPermissionsQuery.data?.permissions,
    myPermissionsQuery.isPending,
  ]);
};
