/**
 * The only one environment variables entry.
 * Do not use process.env directly.
 * Use these variables instead.
 */
import { toBoolean } from "@chatbotgang/etude/pitch-shifter/boolean";
import { integer } from "@chatbotgang/etude/pitch-shifter/integer";
import { nativeEnum } from "@chatbotgang/etude/pitch-shifter/nativeEnum";
import { toString } from "@chatbotgang/etude/pitch-shifter/string";

export const ENV_TEST = import.meta.env["NODE_ENV"] === "test";

export const TITLE = toString(import.meta.env["VITE_TITLE"]);

/** Interlude API service host */
export const INTERLUDE_API_HOST = toString(
  import.meta.env["VITE_INTERLUDE_API_HOST"],
);

/** Basic environment variables */
/** in development */
export const DEVELOPMENT_MODE = import.meta.env.DEV === true;

export const FIREBASE_LOCALE_STORAGE_API_KEY = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_API_KEY"],
);

export const FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN"],
);

export const FIREBASE_LOCALE_STORAGE_PROJECT_ID = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_PROJECT_ID"],
);

export const FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET"],
);

export const FIREBASE_LOCALE_STORAGE_APP_ID = toString(
  import.meta.env["VITE_FIREBASE_LOCALE_STORAGE_APP_ID"],
);

export const DEFAULT_QUERY_RETRY_MAX_COUNT = integer()(
  import.meta.env["VITE_DEFAULT_QUERY_RETRY_MAX_COUNT"],
);

enum EnvEnum {
  "staging" = "staging",
  "production" = "production",
}

export const ENV = nativeEnum(
  EnvEnum,
  EnvEnum.staging,
)(import.meta.env["VITE_ENV"]);

enum ServerRegionEnum {
  "tw" = "tw",
  "jp" = "jp",
}

export const SERVER_REGION = nativeEnum(
  ServerRegionEnum,
  ServerRegionEnum.tw,
)(import.meta.env["VITE_SERVER_REGION"]);

export const SERVER_REGION_ENV: `${typeof SERVER_REGION}-${typeof ENV}` = `${SERVER_REGION}-${ENV}`;

export const SENTRY_RELEASE = toString(import.meta.env["VITE_SENTRY_RELEASE"]);
export const SENTRY_DSN = toString(import.meta.env["VITE_SENTRY_DSN"]);

export const ENABLE_ROUTER_DEV_TOOL = toBoolean(
  import.meta.env["VITE_ROUTER_DEV_TOOL"],
  { falsyString: ["false", "0"] },
);

export const IS_STORYBOOK = import.meta.env["IS_STORYBOOK"] === "true";

export const GA4_ID = toString(import.meta.env["VITE_GA4_ID"]);

// Facebook
export const FB_APP_ID = toString(import.meta.env["VITE_FB_APP_ID"]);
export const WHATSAPP_CONFIG_ID = toString(
  import.meta.env["VITE_WHATSAPP_CONFIG_ID"],
);
export const FB_CURSOR_BASED_PAGINATION_LIMIT = integer()(
  import.meta.env["VITE_FB_CURSOR_BASED_PAGINATION_LIMIT"],
);

// CrescendoLab
export const CL_WEB_SDK_EMBED_CODE_URL = toString(
  import.meta.env["VITE_CL_WEB_SDK_EMBED_CODE_URL"],
);
export const CL_WEB_SDK_EMBED_SHOPIFY_CODE_URL = toString(
  import.meta.env["VITE_CL_WEB_SDK_EMBED_SHOPIFY_CODE_URL"],
);

// Statsig Sdk
export const STATSIG_CLIENT_API_KEY = toString(
  import.meta.env["VITE_STATSIG_CLIENT_API_KEY"],
);
export const CAAC_URL = toString(import.meta.env["VITE_CAAC_URL"]);
