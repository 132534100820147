import { Stack } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@/components/Button";
import type { ModalProps } from "@/components/Modal";
import { Modal } from "@/components/Modal";
import { Typography } from "@/components/Typography";

interface Props extends Omit<ModalProps, "children" | "title" | "footer"> {}

export const WccsPlanExpiredModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      title={t("page.channel.wccs.planExpiredModal.title")}
      footer={
        <Stack gap="12px" direction="row" justifyContent="flex-end">
          <Button
            component="a"
            variant="primary"
            rel="noopener noreferrer"
            href={t("page.channel.wccs.planExpiredModal.footer.contactUs.link")}
          >
            {t("page.channel.wccs.planExpiredModal.footer.contactUs")}
          </Button>
        </Stack>
      }
    >
      <Typography>
        <Trans i18nKey="page.channel.wccs.planExpiredModal.desc" />
      </Typography>
    </Modal>
  );
};
