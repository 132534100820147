import {
  RootQueriesContext,
  useRootQueries,
} from "@/queriesContext/rootQueriesContext/context";

export const RootQueriesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const queries = useRootQueries();
  return (
    <RootQueriesContext.Provider queries={queries}>
      {children}
    </RootQueriesContext.Provider>
  );
};
